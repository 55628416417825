import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

export enum OfflineTimer {
  CountdownTime = 1000 * 60
}
@Injectable({
  providedIn: 'root'
})
export class OnlineService {

  private timeoutId: any;
  private countdownId: any;
  private countdownValue: number;

  userOffline: Subject<boolean> = new Subject();

  constructor(public router: Router) {
    this.reset();
    // this.initListener();
  }

  initListener() {
    //  window.addEventListener('offline', () => this.startCountdown());
  }

  reset() {
    clearTimeout(this.timeoutId);
    clearTimeout(this.countdownId);
  }

  startCountdown() {
    this.countdownValue = OfflineTimer.CountdownTime / 1000;
    this.countdownId = setInterval(() => {
      this.countdownValue--;
      console.log('You will be loggedout in:', this.countdownValue, 'seconds');
      if (this.countdownValue <= 0) {
        clearInterval(this.countdownId);
        console.log('User offline');
        this.userOffline.next(true);
      }
    }, 1000);
  }
}

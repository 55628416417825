<style>
  .sidemenu {
    background-color: cornflowerblue;
  }

  input[type=submit] {
    color: white;
  }

  .apadding {
    padding: 1vh;
    /* margin: 1vh !important         */
  }

  li {
    list-style: none;
    float: left;
    padding: 0.2vh;
  }
</style>

<header id="header">

  <!-- <div class="d-lg-block"> -->
  <!-- <ul>
          <li><a class="sidemenu apadding" routerLink="/question"><input
                      style="background-color: cornflowerblue;border:none;" type="submit"
                      value="Previous"></a></li>
          <li><a class="sidemenu apadding" routerLink="/question"><input
                      style="background-color: cornflowerblue;border:none" type="submit" value="Next"></a>
          </li>
          <li><a class="apadding" style=" background-color:#ee6910;" routerLink="/question"><input
                      style=" background-color:#ee6910; border:none;" type="submit" value="Save"></a>
          </li>
          <li><a class="apadding" style=" background-color:rgb(199, 49, 49);" routerLink="/question"><input
                      style="background-color:rgb(199, 49, 49); border:none;" type="submit"
                      value="Finish/Submit"></a></li>
      </ul> -->

  <!-- </div> -->
  <div class="card-body row">
    <!-- <button type="button" class="btn btn-default">Default</button>
    <button type="button" class="btn btn-primary">Primary</button>
    <button type="button" class="btn btn-secondary">Secondary</button>
    <button type="button" class="btn btn-danger">Danger</button>
    <button type="button" class="btn btn-success">Success</button> -->
    <div class="col-sm-12 col-md-12 clock_timer user_functs" style="color: red;">
      <!-- <label class="mr-2">Timeleft:</label> -->

      <i class="ni ni-time-alarm mr-2 timer-icon"></i>
      <!-- <div > -->
      <span class="timer-wrap">
        <countdown *ngIf="timeleft !== undefined" #countdown [config]="{leftTime: timeleft, notify: [ 300, 600 ]}" (event)="handleEvent($event)"></countdown>
      </span>
      <!-- </div> -->
    </div>
    <div *ngIf="!adminpaused &&  !proctorpaused" class="col-sm-12 col-md-12 mobileqlist qlist user_functs">
      <!-- <button routerLink="/exam/questionlist" [queryParams]="{scheduleid:scheduleid}" type="button" class="btn btn-outline-secondary">Question List</button> -->
      <button [ngClass]="{'badge-unanswered':!flaggedquestion ,'badge-flagged':flaggedquestion }" title="Flag a question to revisit later" (click)="flagquestionforreview()">
      <i class="fa fa-flag badge-flagged" [ngClass]="{'badge-unanswered':!flaggedquestion ,'badge-flagged':flaggedquestion }"></i></button>
    </div>
    <div *ngIf="!adminpaused &&  !proctorpaused" class="col-sm-12 col-md-12 continual-btn user_functs">
      <div class="mobilebtnsss">
        <div class="row">
          <div class="col" style="text-align: left;">
            <button type="button" *ngIf="qno>1" (click)="prevbtnclicked()" class="btn cornblue">
              <span><i class="fa fa-long-arrow-left"></i></span> {{language.previous}}
            </button>
          </div>
          <div class="col" style="text-align: right;">
            <button type="button" *ngIf="!islast" (click)="nextbtnclicked()" class="btn cornblue">
              <span><i class="fa fa-long-arrow-right"></i></span> {{language.next}}
            </button>
          </div>

          <!-- <button type="button" routerLink="/exam/submit" [queryParams]="{url:url,scheduleid:scheduleid}" class="btn btn-warning">Finish/Submit</button> -->
          <div *ngIf="!adminpaused &&  !proctorpaused" class="col-sm-12 submit_finish">
            <button *ngIf="islast" type="button" (click)="finishclicked()" class="btn btn-danger"> {{language.finishAndSubmit}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

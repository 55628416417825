<label class="radio radio-gradient">
    <span class="radio__input">
      <input type="radio" #optionInput [name]="schedulequestionid" [class]="schedulequestionid" [value]="option.optionid" id="{{option.optionid}}" [checked]=option.selected (change)="onRadioButtonClick(option)">
      <span class="radio__control"></span>
    </span>
    <span #optionhtmlspan class="radio__label" style=" font-size: 18px !important;"></span>
  </label>

  <!-- <input name="custom-radio-1" class="custom-control-input lead" type="radio" [value]="option.optionid" (click)="onRadioButtonClick(option)">
  <label class="custom-control-label lead" [innerHTML]="option.optionhtml"></label> -->

import { Component, OnInit, ElementRef, ViewChild, OnDestroy, Host, AfterViewInit, HostListener, EventEmitter, Output } from '@angular/core';
import { MeetingSettings } from '../models/MeetingSettings';
import { Router, PRIMARY_OUTLET, UrlSegmentGroup, ActivatedRoute } from '@angular/router';
import { ParticipantVideoSetupViewComponent } from '../participant-video-setup-view/participant-video-setup-view.component';
import { MeetingService } from '../services/meeting.service';
import { MeetingLite } from '../models/Meeting';
import { UtilService } from '../services/util.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
//import { CookieService } from 'ngx-cookie-service';
import { MeetingHandlerService } from '../services/meeting-handler.service';
//import {Injector} from "@angular/core";

declare const $: any;

@Component({
  selector: 'app-select-media',
  templateUrl: './select-media.component.html',
  styleUrls: ['./select-media.component.scss']
})
export class SelectMediaComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('settingsVideo', { static: false }) settingsVideo: ParticipantVideoSetupViewComponent;
  inMeeting: boolean = false;
  //settings: Settings;
  meetingid: string;
  videostream: MediaStream;
  audiostream: MediaStream;
  joining = false;
  localuserinfo: any = {};
  @ViewChild('joinbutton') joinbutton: ElementRef;
  @ViewChild('recordMeetingCheckbox') recordMeetingCheckbox: ElementRef;
  startingmeeting: boolean = false;
  meetinginfo: MeetingLite = new MeetingLite();
  waitingforstart: boolean = false;
  startParticipantsMuted: boolean = false;
  allowNonHostsRecord: boolean = false;
  //displayMode: string;// = "meetingmode";// "focusmode";
  //mode = "focusmode";
  recordMeeting = false;
  attendanceInterval: number = 20;
  takeAttendance: boolean = false;
  recorder = false;
  ready = false;
  test = false;
  username = "";
  authorizedUser;
  selectedCamera;
  selectedMicrophone;

  //@Output() settingToggled: EventEmitter<void> = new EventEmitter<void>();

  // enableaudiofortest: boolean;
  // enablevideofortest: boolean;
  constructor(private router: Router,
    private activatedroute: ActivatedRoute,
    public settings: MeetingSettings,
    private meetingservice: MeetingService,
    private meetingHandler: MeetingHandlerService,
    public util: UtilService,
    private toastr: ToastrService,
    private titleService: Title,
    //private cookieService:CookieService
    ) {
    //this.settings = Settings.getSettings();
    this.recorder = this.activatedroute.snapshot.queryParams.recorder;
    //this.displayMode = this.util.meetingmode;
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    //console.log(`visibility state: ${document.visibilityState}`);
    if (this.meetingHandler.domain) {
      this.meetingservice.deassignMeetingServer(this.meetingHandler.meetingid, this.meetingHandler.jwt, this.meetingHandler.clientInstanceId);
    }
    // if(this.meetingHandler.usersIncremented){
    //   this.meetingservice.decrementUsers(this.meetingHandler.meetingid, this.meetingHandler.jwt).toPromise();//no need to await
    // }
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {
    if (this.videostream) {
      this.videostream.getVideoTracks().forEach((track) => {
        track.stop();
      });
    }
    if (this.audiostream) {
      this.audiostream.getAudioTracks().forEach((track) => {
        track.stop();
      });
    }
  }

  async populateVideoAndAudioSelects(showPreviews = true) {
    //we should never change the settings here. We are getting the settings manually to make a point that this should only be used occasionally here and in enterMeeting()
    const settings = this.settings;
    await this.util.setDevicesList();
    if (this.inMeeting) {
      //if we're in a meeting, then we'll use the settings that are already in use
      this.settingsVideo.audioEnabled = settings.enableMicrophone;
      this.settingsVideo.videoEnabled = settings.enableCamera;
      this.selectedCamera = this.settings.selectedCamera ?? this.util.defaultCamera;
      this.selectedMicrophone = this.settings.selectedMicrophone ?? this.util.defaultMicrophone;
    }
    else {
      //if we're here, we haven't entered the meeting yet, so we'll setup default values
      //we're doing this to make sure we first request access to the video and audio devices so that we get the proper device names
      const mediaAccessibilityArray = await Promise.all([this.util.testAudioAccessible(), this.util.testVideoAccessible()]);

      this.settingsVideo.audioEnabled = mediaAccessibilityArray[0];
      this.settingsVideo.videoEnabled = mediaAccessibilityArray[1];
      this.selectedCamera = this.util.defaultCamera;
      this.selectedMicrophone = this.util.defaultMicrophone;
    }

    if (showPreviews) {
      await this.showPreviews();
    }
    //}
  }

  async showPreviews() {
    const promises = [];

    if (this.settingsVideo.videoEnabled || !this.inMeeting) {
      promises.push(this.showVideoPreview());
    }
    if (this.settingsVideo.audioEnabled || !this.inMeeting) {
      promises.push(this.showAudioPreview());
    }

    await Promise.all(promises);
  }

  navigateToMeetingEnded() {
    this.toastr.info("This meeting has now ended")
    setTimeout(() => {
      this.router.navigate([`/exam/userexams`]);
      
    }, 5*1000);
  }
  async ngOnInit() {
    const jwt = this.activatedroute.snapshot.queryParams.auth;
    //debugger;


    if (this.activatedroute.snapshot.queryParams.inMeeting !== undefined) {
      this.inMeeting = this.activatedroute.snapshot.queryParams.inMeeting;
    }

    if (!this.inMeeting) {

      this.meetingid = this.activatedroute.snapshot.params.meetingid;

      try {
        this.meetinginfo = await this.meetingservice.getMeeting(this.meetingid).toPromise();
        this.allowNonHostsRecord = this.meetinginfo.allowNonHostsRecord;
        this.startParticipantsMuted = this.meetinginfo.startParticipantsMuted;
        this.takeAttendance = this.meetinginfo.takeAttendance;
        this.attendanceInterval = this.meetinginfo.attendanceInterval;
      }
      catch (error) {
        console.error(error);
        this.handleJoiningError();
      }

      if (!jwt) {
        this.authorizedUser = false;
        if (this.meetinginfo.allowPublicAccess) {
          this.username = this.util.getVCircleUsername() ?? '';
          $('#usernamemodal').modal('show');
        }
        else {
          $('#usernamemodal').modal('hide');
          this.toastr.error('Only authorised users allowed in this meeting. Please contact the host');
          this.router.navigate(['/exam/meetings']);
        }
      }
      else {
        this.authorizedUser = true;
        $('#usernamemodal').modal('hide');

        try {

          this.titleService.setTitle(`${this.meetinginfo.title} - VCircle`);
          this.localuserinfo = await this.meetingservice.decodeJWT(this.meetingid, jwt).toPromise();
        }
        catch (error) {
          console.log('Error: ', error);
          this.handleJoiningError();
        }

        // if(this.meetinginfo.ended){
        //   this.navigateToMeetingEnded(jwt);
        //   return;
        // }

        this.test = this.activatedroute.snapshot.queryParams.test == 'true';

        if (this.activatedroute.snapshot.queryParams.recordMeeting) {
          this.recordMeeting = this.activatedroute.snapshot.queryParams.recordMeeting == 'true';
          //debugger;
          if (this.recordMeeting) {//i.e if recordMeeting was set from the url, then disable the checkbox so it can't be unchecked
            setTimeout(() => {
              if (this.recordMeetingCheckbox) {
                this.recordMeetingCheckbox.nativeElement.disabled = true;
              }
            }, 500);
          }
        }
        /*if(this.test){
          this.settings.enableMicrophone = this.activatedroute.snapshot.queryParams.enableaudio == 'true';
          this.settings.enableCamera = this.activatedroute.snapshot.queryParams.enablevideo == 'true';
        }*/
        //this.enableaudiofortest = this.activatedroute.snapshot.queryParams.enableaudio == 'true';
        //this.enablevideofortest = this.activatedroute.snapshot.queryParams.enablevideo == 'true';

        if (this.recorder) {
          setTimeout(async () => {
            this.joinbutton.nativeElement.click();
          }, 1000);
          return;
        }
      }

      await this.populateVideoAndAudioSelects();

      this.ready = true;

      if (this.test) {
        setTimeout(async () => {
          this.joinbutton.nativeElement.click();
        }, 1000);
      }
    }
  }



  async enterMeeting() {

    if (this.takeAttendance && this.attendanceInterval < 5) {
      this.toastr.error("Attendance interval cannot be less than 5 minutes");
      return;
    }
    else {



      // if(this.mobile()){
      //   this.util.enterFullscreen();
      // }

      this.joining = true;
      //console.log('Current url: ' + this.router.url);
      //this.populateVideoAndAudioSelects();
      //const parsedurl = this.router.parseUrl(this.router.url);
      //const segmentgroup: UrlSegmentGroup = parsedurl.root.children[PRIMARY_OUTLET];
      //console.log('segments: ', segmentgroup.segments);
      //console.log('parsed url: ', parsedurl);

      //let confirmServerStartedInterval;

      // if(this.util.mobile()){
      //   this.displayMode = this.util.meetingmode;
      // }
      // else{
      //   if(this.meetinginfo.mode == this.util.focusmode){
      //     this.displayMode = this.meetinginfo.mode;
      //   }
      // }


      const jwt = this.activatedroute.snapshot.queryParams.auth;

      let connected = false;
      while (!connected) {
        try {
          this.meetinginfo = await this.meetingservice.getMeeting(this.meetingid).toPromise();

          if (!this.meetinginfo.started || this.meetinginfo.ended) {//if the meeting has not been started, we need to start the meeting IF the user is the host


            if(this.meetinginfo.ended){
             this.navigateToMeetingEnded()
             break;
            }
            console.log('meeting hasn\'t been started');
            if (this.localuserinfo.host) {
              //start meeting
              console.log('starting meeting');
              this.startingmeeting = true;

              const startpromises = [];
              const numparticipantsstring = this.activatedroute.snapshot.queryParams.numparticipants;
              const numparticipants = parseInt(numparticipantsstring);
              startpromises.push(this.meetingservice.startMeeting(this.meetingid, jwt, this.startParticipantsMuted, this.takeAttendance, this.attendanceInterval, numparticipants, this.allowNonHostsRecord).toPromise());

              Promise.all(startpromises);
            }
            else {
              //this.meetingservice.setLookingForServer(this.meetingid, jwt);//notify the system that a user is in need of a server. no real need to await it
              console.log('waiting for the host to start the meeting');
              this.waitingforstart = true;
            }
            //retry();
          }
          // else if(this.meetinginfo.ended){
          //   this.navigateToMeetingEnded(jwt);
          // }
          else {// if(this.meetinginfo.domainReady){
            //confirm that we can reach the mediasoup service
            console.log('meeting has been started');
            try {
              if (!this.meetingHandler.initialised) {
                //debugger;
                this.meetingHandler.meetingid = this.activatedroute.snapshot.params.meetingid;
                this.meetingHandler.jwt = this.activatedroute.snapshot.queryParams.auth;
                this.meetingHandler.recorder = this.activatedroute.snapshot.queryParams.recorder;
                this.meetingHandler.test = this.activatedroute.snapshot.queryParams.test == 'true';
                //if we are here, it means that everything is in order
                await this.meetingHandler.init();
              }

              const domain = await this.meetingHandler.getMeetingServer();


              //await this.meetingservice.confirmMediasoupStarted(domain, this.localuserinfo.username);
              // const recorderreadiness = await this.meetingservice.confirmRecorderReady(this.meetinginfo.meetingId);
              // if(!recorderreadiness.ready && !this.recorder){
              //   continue;
              // }

              console.log('calling connect to meeting until successful from enterMeeting()');
              await this.meetingHandler.connectToMeetingUntilSuccessful(true);

              connected = true;

              //we are updating the media settings down here so that the user can change the settings up until the last second before we actually go to the meeting
              const settings = this.settings;

              settings.enableCamera = this.settingsVideo.videoEnabled;
              settings.enableMicrophone = this.settingsVideo.audioEnabled;

              if (this.meetinginfo.mode == this.util.focusmode && !this.localuserinfo.host) {
                settings.enableCamera = false;
                settings.enableMicrophone = false;
              }

              //debugger;
              settings.selectedMicrophone = this.selectedMicrophone;
              settings.selectedCamera = this.selectedCamera;

              //this.recordingEventsService.recordOnJoin = this.recordMeeting;


              //startpromises.push(this.meetingservice.recordMeeting(this.meetingid, jwt).toPromise());
              //this.toastr.success('Recording Started...');

              // if (this.recordMeeting) {
              //   this.recordingService.startRecordingImmediately = true;
              // }

              this.goToMeeting(jwt);
            }
            catch (error) {
              console.log(error);

              continue;
            }
          }
        }
        catch (error) {
          console.error(error);
          this.toastr.error('Error joining...Retrying...');
        }

        if (!connected) {
          await this.util.sleep(5 * 1000);
        }
      }
    }
  }

  private goToMeeting(jwt: string) {
    // if(this.util.mobile()){
    //   this.util.enterFullscreen();
    // }
    const queryParams: any = { auth: jwt };
    if (this.activatedroute.snapshot.queryParams.recorder) {
      queryParams.recorder = this.activatedroute.snapshot.queryParams.recorder;
    }
    if (this.test) {
      queryParams.test = this.test;
    }
    if (this.activatedroute.snapshot.queryParams.debug) {
      queryParams.debug = this.activatedroute.snapshot.queryParams.debug;
    }

    this.router.navigate([`/exam/meeting/${this.meetingid}`], { queryParams: queryParams });

  }
  //confirmServerStartedInterval = setInterval(confirmServerStarted, 5000);    



  private handleJoiningError() {
    this.toastr.error('Error...Retrying...');
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  }

  onCameraChange(deviceid: string) {
    this.selectedCamera = deviceid;
    //this.settings.selectedCamera = deviceid;
    this.showVideoPreview();
    //this.settingToggled.emit();
  }

  onMicrophoneChange(deviceid: string) {
    this.selectedMicrophone = deviceid;
    //this.settings.selectedMicrophone = deviceid;
    this.showAudioPreview();
    //this.settingToggled.emit();
  }

  stopAudioStream() {
    if (this.audiostream) {
      this.audiostream.getAudioTracks().forEach((t) => {
        t.stop();
      });
    }
    this.audiostream = null;
  }

  stopVideoStream() {
    if (this.videostream) {
      this.videostream.getAudioTracks().forEach((t) => {
        t.stop();
      });
    }
    this.videostream = null;
  }

  async showVideoPreview() {
    if (this.settingsVideo.videoEnabled) {
      try {
        this.videostream = await navigator.mediaDevices.getUserMedia({ video: this.settings.getVideoConstraints(this.selectedCamera) });
        //this.settingsVideo.muteLocally(true);//.video.nativeElement.muted = true;
        this.settingsVideo.setVideoStream(this.videostream);
      }
      catch (error) {
        this.settingsVideo.videoEnabled = false;
        this.toastr.error('Error getting video stream. Please ensure you have enabled camera access permissions');
        this.stopVideoStream();
      }
    }
    else {
      this.stopVideoStream();
    }

    if (this.videostream) {
      this.settingsVideo.videoEnabled = this.videostream.getVideoTracks().length > 0;
      //this.settingsVideo.audioEnabled = this.stream.getAudioTracks().length > 0;
    }
    else {
      this.settingsVideo.videoEnabled = false;
      //this.settingsVideo.audioEnabled = false;
    }

    try {
      this.settingsVideo.setVideoStream(this.videostream);
    }
    catch (error) {
      console.error(error);
    }
  }


  async showAudioPreview() {
    if (this.settingsVideo.audioEnabled) {
      try {
        this.audiostream = await navigator.mediaDevices.getUserMedia({ audio: this.settings.getAudioConstraints(this.selectedMicrophone) });
        await this.settingsVideo.setAudioStream(this.audiostream);
        //this.settingsVideo.muteLocally(true);//.video.nativeElement.muted = true;
        //this.settingsVideo.setVideoStream(this.videostream);
      }
      catch (error) {
        this.settingsVideo.audioEnabled = false;
        this.toastr.error('Error getting audio stream. Please ensure you have enabled microphone access permissions');
        this.stopAudioStream();
      }
    }
    else {
      this.stopAudioStream();
    }

    if (this.audiostream) {
      this.settingsVideo.audioEnabled = this.audiostream.getAudioTracks().length > 0;
    }
    else {
      this.settingsVideo.audioEnabled = false;
    }
    try {
      await this.settingsVideo.setAudioStream(this.audiostream);
    }
    catch (error) {
      console.error(error);
    }
  }

  async videoSettingToggled() {
    //this.settings.enableCamera = participantVideo.videoEnabled;
    //this.settings.enableMicrophone = participantVideo.audioEnabled;
    this.settingsVideo.videoEnabled = !this.settingsVideo.videoEnabled;
    await this.showVideoPreview();
    //this.settings.enableCamera = participantVideo.videoEnabled;
    //this.settingToggled.emit();
  }

  async audioSettingToggled() {
    this.settingsVideo.audioEnabled = !this.settingsVideo.audioEnabled;
    await this.showAudioPreview();
    //this.settings.enableMicrophone = participantVideo.audioEnabled;
    //this.settingToggled.emit();
  }

  greaterThan(a: number, b: number) {
    return a > b;
  }

  async publicuserContinue() {
    const meetingid = this.activatedroute.snapshot.params.meetingid;
    let auth: any = await this.meetingservice.getPublicUserToken(meetingid, this.username, this.username).toPromise();
    this.util.setVCircleUsername(this.username);
    //this.cookieService.set('vcircle-public-user', this.username);
    $('#usernamemodal').modal('hide');
    return this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([`/exam/join/${meetingid}`], { queryParams: { auth: auth.jwt } });
    });

  }

  identifyDevice(index, device) {
    return device.DeviceId;
  }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from '../services/language.service';
declare var $: any
declare const MediaRecorder: any;
@Component({
  selector: 'app-system-check',
  templateUrl: './system-check.component.html',
  styleUrls: ['./system-check.component.css']
})
export class SystemCheckComponent implements OnInit {
  cameraAvailable: boolean;
  microphoneAvailable: boolean;
  speakerAvailable: boolean;
  osVersion: string;
  browserVersion: string;
  isDesktopDevice: boolean;
  internetSpeedMBps: number;
  warnings: string[] = [];
  isLoading: boolean = false;
  latency: any = 0;
  deviceType: string;
  isMicrophoneActive: boolean = false;
  microphoneVolume: number = 0;
  isPlaying: boolean = false;
  showContinue: boolean = false;
  canRecord: boolean = false;
  private audioContext: AudioContext | null = null;
  private audioAnalyser: AnalyserNode | null = null;
  private dataArray: Uint8Array | null = null;
  @ViewChild('videoElement', { static: false }) videoElement!: ElementRef;
  @ViewChild('canvasElement', { static: false }) canvasElement!: ElementRef;
  capturedImage: string | null = null;
  camError: string;
  micError: string;
  videostream: any;
  recorderBps = (480 * 240 * 24 * 1 * 0.07);
  audioStream: MediaStream;
  speakerResponse: any;
  cameraResponse: any;
  checkSpeakerCliked: boolean;
  constructor(public activatedroute: ActivatedRoute, public router: Router, private languageService: LanguageService) { }
  get language() {
    return this.languageService.language;
  }

  ngOnInit() {
    this.osVersion = this.language.unknown
    this.browserVersion = this.language.unknown;
    this.deviceType = this.language.unknown;
    console.log(this.speakerResponse);

  }


  closeCheck() {
    if ((!this.speakerResponse ||  this.speakerResponse =='No') && this.speakerAvailable) {
      this.warnings.push(this.language.audioNotAvailablePlease)
    }
    if ((!this.cameraResponse ||  this.cameraResponse =='No') && this.speakerAvailable) {
      this.warnings.push(this.language.cameraNotAvailablePlease)
    }
  }

  runCheck(showModal: boolean = false) {
    this.warnings = [];
    this.cameraAvailable = false;
    this.microphoneAvailable = false;
    this.speakerAvailable = false;
    this.isDesktopDevice = false;
    this.isMicrophoneActive = false;
    this.showContinue = false;
    this.isPlaying = false;
    this.speakerResponse = undefined;
    this.checkSpeakerCliked = false;
    this.cameraResponse = undefined;

    this.checkCamera().then(result => this.cameraAvailable = result);
    this.checkMicrophone().then(result => this.microphoneAvailable = result);
    this.checkRecording().then(result => this.canRecord = result);
    this.osVersion = this.getOSVersion();
    this.browserVersion = this.getBrowserVersion();
    this.isDesktopDevice = this.isDesktop();
    this.deviceType = this.isDesktopDevice ? this.language.desktop : this.language.mobile;

    this.checkForManyCameras().then(result => {
      if (result.hasDuplicates) {
        this.warnings.push(`${this.language.duplicateCameratyprDetected}. (${result.names})`);
      }
    }).catch(error => {
      console.log('Error checking for duplicate cameras: ' + error.message);
    });

    if (!this.isDesktopDevice) {
      this.warnings.push(this.language.itRecommendedUseDesktopDevice);
    }

    if (showModal) {
      $('#systemcheckModal').modal('show');
    }
    this.showContinue = true;
  }


  // Check if camera is working
  checkCamera(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          this.videostream = stream;
          const videoTracks = this.videostream.getVideoTracks();
          const video = this.videoElement.nativeElement;
          video.srcObject = stream;
          if (videoTracks.length > 0 && videoTracks[0].enabled) {
            resolve(true);
          } else {
            this.warnings.push(this.language.noWorkingCamerafound)
            reject('No working camera found.');
          }
          //this.captureImage()
        })
        .catch(error => {
          console.log(error);
          this.warnings.push(this.language.failedToAccessCamera + error.message);
          this.camError = this.language.failedToAccessCamera + error.message;
          reject('Failed to access camera: ' + error.message);
        });
    });
  }
  // Check if the microphone is available
  checkMicrophone(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
          this.audioStream = stream;
          const audioTracks = this.audioStream.getAudioTracks();

          if (audioTracks.length > 0 && audioTracks[0].enabled) {
            resolve(true);
          } else {
            reject('No working microphone found.');
          }
          this.isMicrophoneActive = true;
          this.audioContext = new AudioContext();
          const audioSource = this.audioContext.createMediaStreamSource(stream);

          // Create an AnalyserNode for audio analysis
          this.audioAnalyser = this.audioContext.createAnalyser();
          audioSource.connect(this.audioAnalyser);

          // Setup analyser properties
          this.audioAnalyser.fftSize = 256;
          const bufferLength = this.audioAnalyser.frequencyBinCount;
          this.dataArray = new Uint8Array(bufferLength);

          this.visualizeMicrophone();
        })
        .catch(error => {
          this.isMicrophoneActive = false;
          this.micError = this.language.failedToAccessMicrophone + error.message;
          this.warnings.push(this.language.failedToAccessMicrophone + error.message);
          reject('Failed to access microphone: ' + error.message);
        });
    });
  }

  visualizeMicrophone(): void {
    if (this.audioAnalyser && this.dataArray) {
      this.audioAnalyser.getByteTimeDomainData(this.dataArray);

      // Calculate the average volume level
      let sum = 0;
      for (let i = 0; i < this.dataArray.length; i++) {
        sum += Math.abs(this.dataArray[i] - 128);
      }
      const average = sum / this.dataArray.length;
      this.microphoneVolume = (average / 128) * 100; // Scale to 0-100%

      // Call the function again for the next frame
      requestAnimationFrame(() => this.visualizeMicrophone());
    }
  }

  // Check if speakers are working
  checkSpeakers(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const audio = new Audio('/assets/beep.mp3');
      this.checkSpeakerCliked = true;
      audio.play()
        .then(() => {
          this.speakerAvailable = true
          resolve(true);
        })
        .catch(error => {
          reject('Failed to play sound: ' + error.message);
        });
    });
  }

  // Get operating system information using the user agent string
  getOSVersion(): string {
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf('Win') !== -1) return 'Windows';
    if (userAgent.indexOf('Mac') !== -1 && !/iPhone|iPad|iPod/.test(userAgent)) return 'MacOS'; // macOS
    if (userAgent.indexOf('Linux') !== -1) return 'Linux';
    if (userAgent.indexOf('Android') !== -1) return 'Android';
    if (/iPhone|iPad|iPod/.test(userAgent)) return 'iOS'; // iPhone, iPad, iPod
    return this.language.unknown + ' OS';
  }

  // Get browser information using the user agent string
  getBrowserVersion(): string {
    const userAgent = navigator.userAgent;
    let browserName = this.language.unknownBrowser;
    let fullVersion = this.language.unknownVersion;

    if (userAgent.indexOf('Chrome') !== -1) {
      browserName = 'Chrome';
      fullVersion = userAgent.substring(userAgent.indexOf('Chrome') + 7).split(' ')[0];
    } else if (userAgent.indexOf('Firefox') !== -1) {
      browserName = 'Firefox';
      fullVersion = userAgent.substring(userAgent.indexOf('Firefox') + 8);
    } else if (userAgent.indexOf('MSIE') !== -1 || userAgent.indexOf('Trident/') !== -1) {
      browserName = 'Internet Explorer';
      fullVersion = userAgent.indexOf('MSIE') !== -1
        ? userAgent.substring(userAgent.indexOf('MSIE') + 5).split(';')[0]
        : userAgent.substring(userAgent.indexOf('rv:') + 3).split(' ')[0];
    } else if (userAgent.indexOf('Safari') !== -1 && userAgent.indexOf('Chrome') === -1) {
      browserName = 'Safari';
      fullVersion = userAgent.substring(userAgent.indexOf('Version') + 8).split(' ')[0];
    } else if (userAgent.indexOf('Opera') !== -1 || userAgent.indexOf('OPR') !== -1) {
      browserName = 'Opera';
      fullVersion = userAgent.indexOf('OPR') !== -1
        ? userAgent.substring(userAgent.indexOf('OPR') + 4).split(' ')[0]
        : userAgent.substring(userAgent.indexOf('Opera') + 6).split(' ')[0];
    }

    return `${browserName} ${fullVersion}`;
  }

  // Check if the device is a desktop (includes macOS, excludes iPhones and iPads)
  isDesktop(): boolean {
    const userAgent = navigator.userAgent;

    // Detect macOS and other desktop operating systems
    const isMacDesktop = userAgent.indexOf('Mac') !== -1 && !/iPhone|iPad|iPod/.test(userAgent);
    const isWindowsOrLinux = userAgent.indexOf('Win') !== -1 || userAgent.indexOf('Linux') !== -1;

    return isMacDesktop || isWindowsOrLinux;
  }

  // checkInternetSpeed() {
  //   const image = new Image();
  //   const startTime = new Date().getTime();
  //   const speedTestUrl = 'https://storage.googleapis.com/webfundamentals-assets/videos/media-hub-desktop-720.png';

  //   return new Promise((resolve, reject) => {
  //     image.onload = () => {
  //       const endTime = new Date().getTime();
  //       const duration = (endTime - startTime) / 1000; // time in seconds
  //       const fileSize = 1.9; // Assume 1.9Mb for the image file
  //       const speedMBps = (fileSize / duration); // Speed in MB
  //       const roundedSpeed = Math.round(speedMBps);

  //       resolve(roundedSpeed);
  //     };
  //     image.onerror = (err) => reject(err);
  //     image.src = speedTestUrl;
  //   });
  // }

  // checkNetworkLatency(): Promise<number> {
  //   return new Promise((resolve, reject) => {
  //     const startTime = new Date().getTime();
  //     fetch('https://youtu.be/v4dcFCcSrkQ', { method: 'HEAD', mode: 'no-cors' })
  //       .then(() => {
  //         const latency = new Date().getTime() - startTime;
  //         resolve(latency); // Returns latency in milliseconds
  //       })
  //       .catch(error => {
  //         reject('Failed to check network latency: ' + error.message);
  //       });
  //   });
  // }


  checkForManyCameras(): Promise<{ hasDuplicates: boolean, cameras: MediaDeviceInfo[], names: string[] }> {
    return navigator.mediaDevices.enumerateDevices().then(devices => {
      const cameras = devices.filter(device => device.kind === 'videoinput');
      const cameraIds = cameras.map(camera => camera.deviceId);
      const names = cameras.map(camera => camera.label);

      // Check for 2 cameras by comparing the length of the array with the Set (which removes duplicates)
      const uniqueCameraIds = new Set(cameraIds);
      const hasDuplicates = cameraIds.length !== 1;

      return {
        hasDuplicates,
        cameras, // Return the list of cameras if needed
        names
      };
    }).catch(error => {
      throw new Error(this.language.failedToEnumerateDevices + error.message);
    });
  }



  detectDevToolsOpen() {
    const threshold = 160;
    const devToolsOpen = window.outerWidth - window.innerWidth > threshold || window.outerHeight - window.innerHeight > threshold;

    if (devToolsOpen) {
      this.warnings.push(this.language.developerToolsAreOpen);
    }
  }

  continueClicled() {
    if (this.activatedroute.snapshot.queryParams.url) {
      this.router.navigateByUrl(this.activatedroute.snapshot.queryParams.url);
    }
    else {
      this.router.navigate(['/login']);
    }
  }



  async checkRecording() {
    const stream = await this.getVideoStream();
    const mediaRecorder = new MediaRecorder(stream, { mimeType: this.getRecordingMimeType(), bitsPerSecond: this.recorderBps });

    let accepted = false;

    let acceptfn: (value: boolean | PromiseLike<boolean>) => void;

    mediaRecorder.ondataavailable = () => {
      if (acceptfn && !accepted) {
        accepted = true;
        mediaRecorder.stop();
        acceptfn(true);
      }
    };

    mediaRecorder.start(10);

    //if 10 seconds have passed, and ondataavailable hasn't fired, then cancel
    this.sleep(10000).then(() => {
      if (acceptfn && !accepted) {
        accepted = true;
        mediaRecorder.stop();
        acceptfn(false);
      }
    });

    return new Promise<boolean>((accept, reject) => {
      acceptfn = accept;
    })
  }
  sleep(milliseconds) {
    return new Promise((resolve, reject) => {
      setTimeout(resolve, milliseconds);
    });
  }
  async getVideoStream() {
    let stream: any;
    if (this.videostream && (this.videostream.trackended || this.videostream.getVideoTracks()[0].readyState == 'ended' || this.videostream.getAudioTracks()[0].readyState == 'ended')) {

      this.videostream = null;
    }
    if (!this.videostream) {
      try {
        stream = await navigator.mediaDevices.getUserMedia({
          video: {
            height: { max: 240 },
            facingMode: 'user'
          },
          audio: true
        });

        [...stream.getAudioTracks(), ...stream.getVideoTracks()].forEach(track => {
          track.onended = () => {
            stream.trackended = true;
            //this.stopPublishing(true);
          };
        });
        //console.log("1");

      }
      catch (error) {
        stream = await navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: 'user'
          },
          audio: true
        });

        //  }
      }

      this.videostream = stream;
    }

    return this.videostream;
  }
  getRecordingMimeType() {
    return MediaRecorder.isTypeSupported('video/webm') ? 'video/webm' : 'video/mp4';
  }

  ngOnDestroy(): void {
    if (this.videostream) {
      this.videostream.getTracks().forEach(track => track.stop());
    }
    if (this.audioStream) {
      this.audioStream.getTracks().forEach(track => track.stop());
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Participant } from '../models/Participant';
import { UtilService } from '../services/util.service';

@Pipe({
  name: 'filterParticipants',
  pure: false
})
export class FilterParticipantsPipe implements PipeTransform {

  constructor(private util: UtilService){

  }
  transform(value: Map<string, Participant>, maxDisplayedParticipants: number): Participant[] {
    //debugger;

    let participants = [...value.values()];

    //if(mode != this.util.meetingmode){
      participants = participants.sort((a: Participant, b: Participant) => {
        return a.order - b.order;
      });

      participants = participants.slice(0, maxDisplayedParticipants);
    //}
    return participants;
  }

}

export class PassportData {
    examno: string;
    File?: File;
}

export class loginDetails {
    username: string;
    password: string;
}

export class OptionDetails {
    optionhtml: string = "";
    optionid: number = 0;
    optionno: number = 0;
    selected: boolean = false;
}

export class Question {
    questionhtml: string;
    questionid: number;
    questionno: number;
}

export class Exam {
    essay: boolean;
    obj: boolean;
}

export class AnswerDetails {
    schedulequestionid: number = 0;
    scheduleid: number = 0;
    questionid: number = 0;
    options: string[];
    essayanswer: string = "";
    essay: boolean = false;
    saved: boolean = false;
}
export class RegisterCandidate {
    companyId: string;
    auth: string;
    examUrlCode: string;
    firstName: string;
    lastName: string;
    otherName: string;
    phoneNo: string;
    emailAddress: string;
    extraField1?: string;
    extraField2?: string;
    extraField3?: string;
    extraField4?: string;
    gender?: string;
    passport?: File;
    examEndDateTime: Date;
    examStartDateTime: Date;
}
export class TimeDisplay {
    position: number;
    displaytime: string;
}
export class CandidateComplaint {
    complaintID: string= "";
    lastName: string= "";
    scheduleId: number=0;
    examId: number=0;
    examNo: string= "";
    response: string= "";
    status: string= "";
    complaintCategory: ComplaintCateory = new ComplaintCateory();
    submitedDate: Date;
    examDetail: Exam =new Exam();
    complaintHelpText: string[]= [];
    responses:ComplaintResponse[] = [];
}

export class ComplaintCateory {
    categoryID: string= "";
    categoryText: string= "";
    helpText: string= "";
}

export class ComplaintResponse {
    complaintID: string = "";
    response: string = "";
    isAdminResponse: boolean;
    responseDate: Date;
}
<!-- <section class="main" *ngIf="options.length>0">
        <div *ngFor="let option of options">
            <app-option 
            [option]="option"
            (radioSelected) = "OnRadioSelected($event)">
            </app-option>
        </div>
</section> -->
<div class="col-md-12" *ngIf="!multiple">
    <div class="custom-control custom-radio mb-3" *ngFor="let option of options">
        <app-option [option]="option" [schedulequestionid]="schedulequestionid" (radioSelected)="OnRadioSelected($event)">
        </app-option>
    </div>
</div>
<div class="col-md-12" *ngIf="multiple">

    <div class="custom-control custom-checkbox mb-3" *ngFor="let option of options">
        <app-option-check [option]="option" [schedulequestionid]="schedulequestionid" (checkchanged)=oncheckchanged($event)></app-option-check>
    </div>
</div> 
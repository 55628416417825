import { ILanguage } from "./ILanguage";

export class Gibberish implements ILanguage {
    languageName = 'Gibberish';
    submitConfirmationMessage: string = 'mnoz flerp glim brop qworf glib!';
    backToLogin: string = 'grib womp krim';
    allComplaints: string = 'vopl flik neld';
    complaintID: string = 'yorn zib glup nop';
    examNo: string = 'klem plurf jib';
    lastName: string = 'trop yurk zen';
    status: string = 'vlopt zim qrox';
    submittedDate: string = 'flarx qirk blan xim';
    noComplaint: string = 'brak zor fline';
    complaint: string = 'jopl grik zopn';
    selectExamComplaint: string = 'flirp glap norn werm frox.';
    natureOfComplaint: string = 'xorl vlopt glim zurp flog.';
    closeComplaints: string = 'griz blim zonp';
    closeReply: string = 'klopt flog plin';
    replyComplaint: string = 'xorp blerp zop vlom';
    reply: string = 'zimpl grop flirk';
    enterResponseDetails: string = 'trop grilp qlom zurp plin gorp';
    submit: string = 'flomp zlop klerp';
    supportDiscussion: string = 'glirz wemp zop plin';
    admin: string = 'trim glonp';
    you: string = 'zop blerp flirz';
    complaintsClosed: string = 'plurp qlem zop tiln';
    replySent: string = 'flim zop groq';
    candidateInformation: string = 'trim zorp wlen flem';
    viewAllComplaints: string = 'trimp wlem zop fnib';
    examinationSelection: string = 'gronk zorq trim zop';
    complaintsDisabled: string = 'plorq qlen zop xlerp blik.';
    complaintCategory: string = 'zoorp glim trix zlem';
    selectOption: string = 'qlorm blix nlen zop';
    complaintDetails: string = 'zlimp glim zop worx';
    solvedYourIssue: string = 'florp plim zlen zorq?';
    yes: string = 'flerp glix zop';
    no: string = 'blim zop xlorp';
    enterComplaintDetails: string = 'zorp glim zop tiln flopz';
    previous: string = 'qlirz glim tilm';
    cancel: string = 'xorn zop glim';
    next: string = 'glirb zop tilm';
    limitOf: string = 'zlimt grim zop blirk';
    wordsMaximum: string = 'zop grim zop tiln';
    welcome: string = 'glirp zop wlen';
    logout: string = 'zlim grim zopx';
    passportSection: string = 'GLIRP ZLORP ZIMQP';
    maxFileUpload1mb: string = 'glim zop tilp wlen zopx';
    takePicture: string = 'trim zop grim tilp';
    clearFaceInstruction: string = 'flirp glim zop grim zopx';
    brightLightInstruction: string = 'xorp glim zop tiln zopx.';
    requestAttention: string = 'zlim glim flop trim';
    uploadPassport: string = 'flirp glim zop tiln';
    unableToConnectToServer: string = 'gron zop flim tilp zop';
    waitForProctorOk: string = 'zlim flim tiln zop glim zopx.';
    uploadSuccessful: string = 'xorn glim zop tilm zopx.';
    savePassport: string = 'glim tilm zopx';
    changePassport: string = 'flirp glim zop tiln zopx';
    examinationFeedBack: string = 'flirp zop tiln glim zopx';
    happy: string = 'zlim tiln zop glim';
    neutral: string = 'glorp tilp zopx';
    unhappy: string = 'flim glim zop tiln zopx';
    close: string = 'glirp zop tiln';
    problemCategories: string = 'flirp zop tiln zop grimx';
    othersProblemCategories: string = 'glirp tilp zop tiln zop grim (qlem zopx)';
    login: string = 'flirp glim zop';
    username: string = 'tiln glim zop tilp';
    password: string = 'glirp glim zop tiln';
    makeComplaint: string = 'flirp glim zop tilp';
    loggedOut: string = 'zlim glim zop tiln zopx';
    userMis_match: string = 'flim glim zop tiln zop';
    loginToTakeExam: string = 'zlim glim zop tiln zop';
    aboutUs: string = 'flirp glim zop tilp';
    error: string = 'glirp glim zopx';
    somethingWentWrong: string = 'flirp glim zop tiln zopx glim zop.';
    pleaseConatctAdministrator: string = 'glirp glim zop tiln zop.';
    instructions: string = 'flirp glim zop tiln zop.';
    question: string = 'glirp glim zop tiln zop';
    pleaseHold: string = 'glirp glim zop tiln zopx';
    examHasBeenPaused: string = 'zlim glim zop tiln zopx';
    reason: string = 'glirp glim zop tiln zop';
    willNotLoseTime: string = 'glirp glim zop tiln zopx';
    userSessionNotFound: string = 'glirp glim zop tiln zopx';
    networkConnectionLost: string = 'glirp glim zop tiln zopx';
    multipleLoginsDetected: string = 'glirp glim zop tiln zopx';
    unauthorizedAccess: string = 'glirp glim zop tiln zopx';
    penalizedWarning: string = 'glirp glim zop tiln zopx';
    youHaveLost: string = 'glirp glim zop tiln zopx';
    leavingExamPage: string = 'glirp glim zop tiln zopx';
    finishAndSubmit: string = 'glirp glim zop tiln zopx';
    questionHasBeen: string = 'glirp glim zop tiln zopx';
    verifyingEmail: string = 'glirp glim zop tiln zopx';
    registration: string = 'glirp glim zop tiln zopx';
    emailAddress: string = 'glirp glim zop tiln zopx';
    verifyEmailAddress: string = 'glirp glim zop tiln zopx';
    otherNames: string = 'glirp glim zop tiln zopx';
    phoneNumber: string = 'glirp glim zop tiln zopx';
    gender: string = 'glirp glim zop tiln zopx';
    selectGender: string = 'glirp glim zop tiln zopx';
    male: string = 'glirp glim zop tiln zopx';
    female: string = 'glirp glim zop tiln zopx';
    scheduleDate: string = 'glirp glim zop tiln zopx';
    scheduleTime: string = 'glirp glim zop tiln zopx';
    selectTime: string = 'glirp glim zop tiln zopx';
    noAvailabeTime: string = 'glirp glim zop tiln zopx';
    register: string = 'glirp glim zop tiln zopx';
    registrationSuccessful: string = 'glirp glim zop tiln zopx';
    yourRegistrationFor: string = 'glirp glim zop tiln zopx';
    wasSuccessful: string = 'glirp glim zop tiln zopx';
    yourExaminationNumberIs: string = 'glirp glim zop tiln zopx';
    checkYourEmailForMoreInformation: string = 'glirp glim zop tiln zopx';
    imageSizeIsGreaterthan1mb: string = 'glirp glim zop tiln zopx';
    invalidImageFormat: string = 'glirp glim zop tiln zopx';
    pleaseSelectGender: string = 'glirp glim zop tiln zopx';
    pleaseFillExaminationSchedule: string = 'glirp glim zop tiln zopx';
    pleaseUploadPassportPicture: string = 'glirp glim zop tiln zopx';
    registrationComplete: string = 'glirp glim zop tiln zopx';
    verificationEmailSent: string = 'glirp glim zop tiln zopx';
    invalidEmail: string = 'glirp glim zop tiln zopx';
    emailVerified: string = 'glirp glim zop tiln zopx';
    invalidCode: string = 'glirp glim zop tiln zopx';
    changeLanguage: string = 'glirp glim zop tiln zopx';
    loading: string = 'glirp glim zop tiln zopx';
    unavailableDateSelected: string = 'glipx qorf trok';
    examPlatformUsageInst: string = 'flin zopx jorb trim zop';
    useSafeExamBrowser: string = 'fron glim zopx wlim xorp';
    sebDownloadLink: string = 'zorq trim zop glim';
    installTheSoftware: string = 'xlim zop glim tiln qorp';
    onceSEBIsInstalled: string = 'florp glim zop tiln';
    here: string = 'xlim zop';
    downloadSEBexamfileforyourexam: string = 'glorp zop tilm glim zop worf';
    youshouldseefile: string = 'flim zop glim tilp zop';
    double_clickdownloadedfile: string = 'zorq tiln glim zop xlim zop';
    confirmInstallSafeExamBrowser: string = 'flirp glim zop tiln xorp';
    unabletoconnecttoserver: string = 'florp glim zop tiln qorp';
    connectionError: string = 'tiln zop glim zorq';
    unknownserversideerror: string = 'florp glim zop tiln qorp xorp';
    youhavebeenpreventedfromstarting: string = 'flirp glim zop tiln zop glim';
    connectiontomediaserverlost: string = 'flirp zop glim tiln qorp';
    verificationError: string = 'zlim glim zop qorp tilm';
    unexpectedCondition: string = 'zorq glim zop tilm qorp';
    submitExamination: string = 'glim zop tiln qorp zorp';
    submitting: string = 'flirp glim zop tilm zorp';
    yourScore: string = 'glirp zop tiln zop glim';
    startNewExam: string = 'xlim zop glim tilm qorp';
    takeExam: string = 'flirp glim zop tiln worp';
    start: string = 'tiln zop glim';
    continue: string = 'zorq glim tiln zop glim';
    scheduleExam: string = 'glirp zop tiln glim qorp';
    pleaseWait: string = 'florp zop glim tiln worf';
    finished: string = 'flirp glim zop tiln zop';
    timeElapsed: string = 'tilm glim zop qorp worp';
    disqualified: string = 'flirp glim zop tiln worp xlim';
    stopped: string = 'glirp zop tilm glim qorp';
    rescheduleExam: string = 'xorp zop glim tiln qorp';
    webtestInstruction: string = 'glim zop tiln qorp worp';
    agreeToInstructions: string = 'flirp zop glim tilm zop worp';
    startExam: string = 'glim zop tiln xorp qorp';
    examSchedule: string = 'zorq glim tiln zop xorp';
    selectDate: string = 'tilm glim zop worp xlim';
    schedule: string = 'glirp zop tiln glim worp';
    examScheduled: string = 'flirp glim zop tiln worp xorp';
    examinationList: string = 'flirp zop glim tiln qorp zop';
    scheduledExaminations: string = 'xlim zop glim worp tilm qorp';
    ensureyourfacefillsthecircle: string = 'flirp glim zop tiln zop xorp tilm';
    capturePhoto: string = 'xlim zop glim qorp tiln';
    retake: string = 'zorq zop glim tiln worp zop';
    upload: string = 'tilm zop glim worp';
    youhavenooutputvideodevice: string = 'flirp glim zop worp tilm qorp';
    errorloadingcustomcssfile: string = 'florp glim zop tiln worp xlim qorp';
    duplicateCameratyprDetected: string = "fghbn rfghn";
    errorCheckingDuplicateCameras: string = "sfdfbfdx fges";
    itRecommendedUseDesktopDevice: string = "erfgb fvgfes";
    virtualMachineDetected: string = "sdsdxcf fgfes";
    noWorkingCamerafound: string = "ssdcx fgvsz";
    failedToAccessCamera: string = "sfdcv  fges";
    noWorkingMicrophoneFound: string = "wdfcv fges";
    failedToAccessMicrophone: string = "ewrdfg fges";
    failedToPlaySound: string = "fgfb fges";
    failedToEnumerateDevices: string = "rfdx fges";
    developerToolsAreOpen: string = "sdrq fges";
    available: string = "erkfddf fges";
    notAvailable: string = "sdkfddf fges";
    proctoringSystemCheck: string = "srymvn mdew";
    camera: string = "yun yre";
    speaker: string = "errkfmvn md";
    microphone: string = "azkfdn md";
    recording: string = "tdvn md";
    osVersion: string = "sdkexvn md";
    browserVersion: string = "sfrv d";
    deviceType: string = "sdgn md";
    virtualMachine: string = "sdkffd";
    safeExamBrowser: string = "s mvn md";
    runCheck: string = "wedfmvn md";
    mediaChecks: string = "sdrtyhbn md";
    cameraCheck: string = "sdkerf md";
    microphoneCheck: string = "sdkfmvn md";
    speakerCheck: string = "dfkjn dc";
    playTestSound: string = "dfkjvbn dcimn ";
    playingTestSound: string = "dkf m do";
    systemCheck: string = "mncbud";
    desktop: string = "dlkcjvn";
    mobile: string = 'djfhv';
    unknown: string = "dkjhb";
    multipleChoice: string = "sdoj osl";
    interview: string = "sdoj osl";
    essay: string = "edf ber";

    flagged: string = "tqw z";
    unflagged: string = "edfw zws";
    firstName: string = 'xyzt pqrn';
    noAvailabeDate: string = 'bklm quop fnad';
    join: string = 'mndl xzit';
    installSafeExamBrowser: string = 'grik pluz jimb fwat woxa jkqi vemb turq bropz.';
    currentSEBVersion: string = 'frool xypl (gzom 3.7.1 jipz flaqr 2024)';
    skipStep3: string = 'pluz krin blomp quib flard.';
    noExamsFound: string = 'trik dop blerp';
    minutes: string = 'yopt qwaf blin';
    selectLanguage: string = 'jibkl xzon frem';
    passportChangeErr: string = 'blirz froqn quim blort';
    unknownBrowser: string = 'blirz dfern';
    unknownVersion: string = "dkmc kjn";
    notUsed: string = "rfg";
    used: string = "rfgbjon";
    detected: string = "refdn";
    notDetected: string = "tghfr";
    canHearBeepSound: string = "ink sdfefv es";
    canSeeSelf: string = "dfkj fkvn";
    audioNotAvailablePlease: string = "msdnx doxk";
    cameraNotAvailablePlease: string = "msdn dkfcmj";
    retakeExam: string = "efgh edfg";
    reasonForRetake: string = "ed idkf";
}
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CbtserviceService } from 'src/app/services/cbtservice.service';
import { ExamService } from 'src/app/services/exam.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

  constructor(
    public router: Router,
    public toastr: ToastrService,
    public examservice: ExamService,
    public activatedroute: ActivatedRoute,private cbtservice: CbtserviceService, private languageService: LanguageService) { }
    get language() {
      return this.languageService.language;
    }

  ngOnInit() {
    let jwt: string = this.activatedroute.snapshot.params.jwt;
    console.log(jwt);
    this.examservice.verifyEmail(jwt).then((res:any)=>{
      console.log(res);
      sessionStorage.setItem('validated', 'true');
      sessionStorage.setItem('email', res.email);
      this.router.navigate([`/register/${res.urlcode}`]);

    }).catch((e)=>{
      this.cbtservice.showHttpError(e)
      this.goto404();
    });

  }
  goto404() {
    this.router.navigate(['/404']);
  }
}

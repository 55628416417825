import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { OptionDetails } from '../model';

@Component({
  selector: 'app-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss']
})
export class OptionComponent implements OnInit, AfterViewInit, OnChanges {
  @Input()
  option: OptionDetails;
  @Input() schedulequestionid:number;
  @ViewChild('optionhtmlspan') optionhtmlspan: ElementRef;
  @Output()
  radioSelected: EventEmitter<OptionDetails> = new EventEmitter();
  @ViewChild('optionInput') optionInput: ElementRef;
  constructor() { }
  
  ngOnChanges(changes: SimpleChanges): void {
    this.setOption();
  }
  ngAfterViewInit(): void {
    this.setOption();
  }

  setOption(){
    if(this.optionhtmlspan){
      if(this.option){
        this.optionhtmlspan.nativeElement.innerHTML =  this.option.optionhtml;
      }
      else{
        this.optionhtmlspan.nativeElement.innerHTML =  '';
      }
    }
  }

  

  ngOnInit(): void {
  }
  onRadioButtonClick(option: OptionDetails) {
    //this.disableradios(true)
    console.log(`checked: ${this.optionInput.nativeElement.checked}`);
    this.radioSelected.emit(option);
  }
  // disableradios(disable: boolean) {
  //   var buttons = document.getElementsByName(this.schedulequestionid.toString());
  //   buttons.forEach(this.radiodiabbletoggle)
  // }
  // radiodiabbletoggle(button) {
  //   button.disabled = true;
  // }
}

<div class="row justify-content-center login-container">

    <!-- Verification Div -->
    <div>
        <div class="col-lg-12 login-form-right">

            <div class="card bg-secondary col-lg-12" *ngIf="!registerSuccess && !valiatedEmail">
                <div class="card-body px-lg-3 py-lg-3">
                    <div class="alert center" style="padding: 0.75em 0; text-align: center;">
                        <p class="font-weight-bold" *ngIf="isLoading"
                            style="color:white; background-color: cornflowerblue;">
                            <span><img style="height:40px;" src="/assets/img/spinner.gif" /></span>
                        </p>
                    </div>

                    <div class="welcome-users">
                        <div class="unique-company-logo">
                            <img *ngIf="this.util.getSubdomain() && logourl" [src]="logourl" width="300"
                                style="max-width: 100%;" alt="login pic" />
                        </div>
                        <p> {{language.registration}}</p>
                    </div>

                    <p class="text-center">({{examName}})</p>
                    <div *ngIf="showForm">

                        <form (ngSubmit)="VerifyEmail()" *ngIf="!codeSent" style="border: 0px !important;">
                            <div class="form-group">
                                <label class="form-control-label">{{language.emailAddress}}</label>
                                <input class="form-control" [placeholder]="language.emailAddress" type="text"
                                    name="confirmedEmail" [pattern]="emailPattern" [(ngModel)]="confirmedEmail">
                            </div>
                            <!-- <a *ngIf="codeSent && !valiatedEmail" class="d-flex align-items-end justify-content-end text-primary"
                            style="font-size: 12px; cursor: pointer;" (click)="VerifyEmail()">Resend Code</a> -->

                            <div class="text-center" *ngIf="!codeSent && !valiatedEmail">
                                <button class="btn btn-primary my-4 form-control" type="submit"
                                    [disabled]="!confirmedEmail || isLoading"> {{language.verifyEmailAddress}}</button>
                            </div>
                        </form>

                        <!-- Code Validation -->
                        <!-- <form (ngSubmit)="VerifyCode()" style="border: 0px !important;">
                            <div class="form-group mb-0" *ngIf="codeSent && !valiatedEmail">
                                <label class="form-control-label">Verification Code</label>
                                <input class="form-control" placeholder="Verification Code" required type="text"
                                    name="code" [(ngModel)]="code">
                            </div>

                            <div class="text-center" *ngIf="codeSent && !valiatedEmail">
                                <button class="btn btn-primary my-4 form-control" type="submit"
                                    [disabled]="!code">Verify
                                    Code</button>
                            </div>
                        </form> -->

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-12 login-form-right">

        <div class="card bg-secondary col-lg-12" *ngIf="!registerSuccess && valiatedEmail">
            <div class="card-body px-lg-3 py-lg-3">
                <div class="alert center" style="padding: 0.75em 0; text-align: center;">
                    <p class="font-weight-bold" *ngIf="isLoading"
                        style="color:white; background-color: cornflowerblue;">
                        <span><img style="height:40px;" src="/assets/img/spinner.gif" /></span>
                    </p>
                </div>

                <div class="welcome-users">
                    <div class="unique-company-logo">
                        <img *ngIf="this.util.getSubdomain() && logourl" [src]="logourl" width="300"
                            style="max-width: 100%;" alt="login pic" />
                    </div>
                    <p> {{language.registration}}</p>
                </div>
                <p class="text-center">({{examName}})</p>

                <form #register="ngForm" name="register" (ngSubmit)="registerclicked()" style="border: 0px !important;"
                    class="login-form" *ngIf="showForm">


                    <div *ngIf="valiatedEmail">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="form-control-label">{{language.firstName}}</label>
                                    <input class="form-control" [placeholder]="language.firstName" required type="text"
                                        name="firstName" [(ngModel)]="candidateData.firstName">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="form-control-label">{{language.lastName}}</label>
                                    <input class="form-control" [placeholder]="language.lastName" required type="text"
                                        name="lastName" [(ngModel)]="candidateData.lastName">
                                </div>

                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="form-control-label"> {{language.otherNames}}</label>
                                    <input class="form-control" [placeholder]="language.otherNames" type="text" name="otherName"
                                        [(ngModel)]="candidateData.otherName">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="form-control-label"> {{language.emailAddress}}</label>
                                    <input class="form-control" [placeholder]="language.emailAddress" [contentEditable]="false"
                                        readonly required type="text" name="emailAddress" [pattern]="emailPattern"
                                        [(ngModel)]="candidateData.emailAddress">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="form-control-label"> {{language.phoneNumber}}</label>
                                    <input class="form-control" [placeholder]="language.phoneNumber" required type="tel"
                                        name="phoeNo" [pattern]="phonePattern" [(ngModel)]="candidateData.phoneNo">
                                </div>
                            </div>

                            <div class="col-md-6" *ngIf="showGender">
                                <label class="form-control-label"> {{language.finishAndSubmit}}</label>
                                <select class="form-control form-select" (change)="setGender($event)">
                                    <option value="" disabled selected> {{language.selectGender}}</option>
                                    <option value="Male"> {{language.male}}</option>
                                    <option value="Female">{{language.female}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="row" *ngIf="selfSchedule">
                            <div class="col-md-6">
                                <label> {{language.scheduleDate}}</label>

                                <div class="form-group" *ngIf="!usedropdown">
                                    <input class="form-control" required type="date" (change)="onDateChange($event)"
                                        [min]="minDate" [max]="maxDate">

                                </div>

                                <div class="form-group" *ngIf="usedropdown">
                                    <select class="form-control form-select" required (change)="onDateChange($event)">
                                        <option value="" disabled selected *ngIf="isDate"> {{language.selectDate}}(yyyy-MM-dd) </option>
                                        <option value="" disabled selected *ngIf="!isDate"> {{language.noAvailabeDate}}</option>
                                        <option *ngFor="let scheduledDate of availabeDates" [value]="scheduledDate">
                                            {{ scheduledDate }}
                                        </option>
                                    </select>

                                </div>
                            </div>
                            <div class="col-md-6">
                                <label> {{language.scheduleTime}}</label>
                                <div class="form-group">

                                    <select class="form-control form-select" required (change)="onTimeChange($event)">
                                        <option value="" disabled selected *ngIf="isTime">  {{language.selectTime}}</option>
                                        <option value="" disabled selected *ngIf="!isTime"> {{language.noAvailabeTime}}</option>

                                        <option *ngFor="let time of availabeTimes" [value]="time.position">
                                            {{ time.displaytime }}
                                        </option>
                                    </select>

                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-md-6" *ngIf="label1 && type1">
                                <div class="form-group">
                                    <label class="form-control-label">{{label1}}</label>
                                    <input *ngIf="type1!=='dropdown'" class="form-control" placeholder="{{label1}}"
                                        required [type]="type1" name="extraField1"
                                        [(ngModel)]="candidateData.extraField1" />

                                    <select *ngIf="type1 === 'dropdown'" class="form-control form-select"
                                        name="extraField1" [(ngModel)]="candidateData.extraField1">
                                        <option *ngFor="let option of option1" [value]="option">{{option}}</option>
                                    </select>
                                </div>

                            </div>
                            <div class="col-md-6" *ngIf="label2 && type2">
                                <div class="form-group">
                                    <label class="form-control-label">{{label2}}</label>
                                    <input *ngIf="type2 !== 'dropdown'" class="form-control" placeholder="{{label2}}"
                                        required [type]="type2" name="extraField2"
                                        [(ngModel)]="candidateData.extraField2" />

                                    <select *ngIf="type2 === 'dropdown'" class="form-control form-select"
                                        name="extraField2" [(ngModel)]="candidateData.extraField2">
                                        <option *ngFor="let option of option2" [value]="option">{{option}}</option>
                                    </select>
                                </div>

                            </div>

                        </div>

                        <div class="row">
                            <div class="col-md-6" *ngIf="label3">
                                <div class="form-group">
                                    <label class="form-control-label">{{label3}}</label>
                                    <input *ngIf="type3 !== 'dropdown'" class="form-control" placeholder="{{label3}}"
                                        required [type]="type3" name="extraField3"
                                        [(ngModel)]="candidateData.extraField3">

                                    <select *ngIf="type3 === 'dropdown'" class="form-control form-select"
                                        name="extraField3" [(ngModel)]="candidateData.extraField3">
                                        <option *ngFor="let option of option3" [value]="option">{{option}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="label4">
                                <div class="form-group">
                                    <label class="form-control-label">{{label4}}</label>
                                    <input *ngIf="type4 !== 'dropdown'" class="form-control" placeholder="{{label4}}"
                                        required [type]="type4" name="extraField4"
                                        [(ngModel)]="candidateData.extraField4">

                                    <select *ngIf="type4 === 'dropdown'" class="form-control form-select"
                                        name="extraField4" [(ngModel)]="candidateData.extraField4">
                                        <option *ngFor="let option of option4" [value]="option">{{option}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="showPassport">
                            <div class="col-md-6">
                                <div class="form-group">

                                    <label class="form-control-label"> {{language.uploadPassport}}</label>
                                    <span>  {{language.maxFileUpload1mb}}</span>
                                    <input class="form-control" required type="file" #passportPix
                                        accept=".png,.jpeg,.jpg" (change)="onFileSelected($event)">

                                </div>

                            </div>

                        </div>
                        <div *ngIf="pix">
                            <img class="displayImg" #passportDisplay style="object-fit:contain;">
                        </div>

                        <div class="text-center">
                            <button type="submit" class="btn btn-primary my-4 form-control"
                                [disabled]="register.invalid || isLoading"> {{language.register}}</button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
        <!-- Success page -->
        <div class="col-lg-12" *ngIf="registerSuccess">
            <div class="card bg-secondary col-lg-9 p-5">
                <div style="border-radius:200px; height:200px; width:200px; background: #F8FAF5; margin:0 auto;">
                    <i class="checkmark">✓</i>
                </div>
                <h1>{{language.registrationSuccessful}}</h1>
                <p>{{language.yourRegistrationFor}} {{examName}} {{language.wasSuccessful}}</p>
                <p>{{language.yourExaminationNumberIs}} <b>{{examNo}}</b>.</p>
                <p>{{language.checkYourEmailForMoreInformation}}</p>
            </div>
        </div>
    </div>
</div>

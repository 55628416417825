<div class="video-container pl-5 m-2" *ngIf="!error">
  <span class="text-warning h2"><b>{{language.ensureyourfacefillsthecircle}}</b></span>
</div>
<div class="video-container pl-5 m-2" *ngIf="!error">
  <video class="candidate-video" [class.show]="!isCaptured" [class.hide]="isCaptured" #video id="video" playsinline autoplay></video>
  <canvas [class.show]="isCaptured" [class.hide]="!isCaptured" #canvas id="canvas"></canvas>
</div>

<div class="snap-container mb-2">
  <button class="btn btn-primary" *ngIf="!isCaptured" (click)="capture()">
    {{language.capturePhoto}}
  </button>
  <button class="btn btn-danger" *ngIf="isCaptured" (click)="removeCurrent()">
    {{language.retake}}
  </button>

  <div *ngIf="error" class="text-danger mt-4">
    {{ error }}.
    <div class="mt-5">
    </div>
  </div>
</div>

<div *ngIf="captured" class="thumbnail mb-2" (click)="save()">
  <img [src]="captured" height="70" /><span style="margin-left: 5px;" class="btn btn-success">{{language.upload}}</span>
</div>

import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserauthenticationserviceService } from '../services/userauthenticationservice.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthguardGuard implements CanActivateChild {

  constructor(private router: Router, private useraccessservice: UserauthenticationserviceService) {

  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.authChecker(state);
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.authChecker(state);
  }

  authChecker(state: RouterStateSnapshot) {
    //for every new page, check the current user again
    if (this.useraccessservice.getCurrentUserToken() != null) {
      let returnVal = this.checkUserSession(state.url);
      return true;
    } else {
      this.router.navigate(["/access/login"], { queryParams: { url: state.url } });
      return false;
    }
  }

  checkUserSession(url) {
    var returnVal: boolean;    
    var x = this.useraccessservice.getCurrentUser().then(
      (data: any) => {
        if (data) {
          return true;
        }
        else {
          console.log("loging user out now");
          this.router.navigate(["/access/login"]);
          return false;
        }
      },
      (err: HttpErrorResponse) => {
        console.log("loging user out now");
        this.router.navigate(["/access/login"]);
        return false;
      }
    );
  }
}

<div class="overlay" [ngClass]="{'show': isloadingdata}">
</div>
<div class="spanner" [ngClass]="{'show': isloadingdata}">
  <div class="spin-wrap">
    <div class="spin-wrap-content">
      <div class="spinner-container">
        <div class="lds-ripple">
          <p *ngIf="isloadingdata && essaystatusmessage">{{essaystatusmessage}}</p>
          <p *ngIf="isloadingdata && markingerrormessage && markingnotworking">{{markingerrormessage}}</p>
          <!-- <div></div>
          <div></div> -->
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div *ngIf="isloadingdata" class="p-5" style="display: flex; justify-content: center;align-items: center; text-align: center; height: 80vh; width: 80vw;">
    <app-loading-spinner></app-loading-spinner>
</div> -->
<div class="toppad mx-2" role="main">
  <!-- <div class="row">
      <div class="col-md-12">
          <app-questionpageheader *ngIf="question.questionCount" [qno]="question.examQuestionSchedule.questionno" [islast]="question.questionCount==question.examQuestionSchedule.questionno" [scheduleid]="exam.scheduleid" [url]="this.activatedroute.snapshot.url" [essayexam]="exam.examtype==Essay"></app-questionpageheader>
      </div>
  </div> -->
  <div class="mb-3">
    <div class="col-md-12">
      <app-question *ngIf="question.questionCount" [isloadingdata]="isloadingdata" (quesunanswered)="quesunanswered($event)" (quesanswered)="quesanswered($event)"
                    [question]="question" [essayexam]="examination.examtype==Essay" [url]="this.router.url" [adminpaused]="adminpaused" [proctorpaused]="proctorpaused" (quesflagged)="quesflagged($event)" [status]="status" [timeleft]="timeleft" (qnoclicked)="qnoclicked($event)">
      </app-question>
      <div *ngIf="adminpaused || proctorpaused" class="my-5" style="text-align: center; vertical-align: middle;"><h4>  {{language.pleaseHold}} </h4><br /><br /><h3> {{language.examHasBeenPaused}}. </h3><br /><br /><h4 *ngIf="pausereason"> {{language.reason}} : {{pausereason | uppercase}}</h4><br /><br /><br /><br /><h5>{{language.willNotLoseTime}} </h5></div>
    </div>
  </div>
  <!-- <div class="row">
      <div class="col-md-12">
          <app-questionpageheader *ngIf="question.questionCount" [qno]="question.examQuestionSchedule.questionno" [islast]="question.questionCount==question.examQuestionSchedule.questionno" [scheduleid]="exam.scheduleid" [url]="this.activatedroute.snapshot.url" [essayexam]="exam.examtype==Essay"></app-questionpageheader>
      </div>
  </div> -->
  <div class="paddedqnos" *ngIf="!adminpaused && !proctorpaused">
    <div class="col-md-12">
      <app-questionpagefooter #quesfooter *ngIf="question.questionCount" 
        [qno]="question.examQuestionSchedule.questionno"
        [noofquestions]="question.questionCount" 
        [scheduleid]="examination.scheduleid" 
        [examid]="examination.examid"
        [schedulequestionid]="question.examQuestionSchedule.schedulequestionid" 
        [questions]="dummyQuestionsForFooter"
        (qnoclicked)="qnoclicked($event)">
      </app-questionpagefooter>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserauthenticationserviceService } from '../services/userauthenticationservice.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { LanguageService } from '../services/language.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private useraccessservice: UserauthenticationserviceService, private toastr: ToastrService,private languageService: LanguageService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUserToken = this.useraccessservice.getCurrentUserToken();
    const currentUserSEBKey = this.useraccessservice.getSEBKey();
    const isLoggedIn = currentUserToken != null;

    if (isLoggedIn && request.url.startsWith(environment.CBTAPIURL)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUserToken}`,
          seb: currentUserSEBKey,
          languageCode:this.languageService.getLanguageCode()
        }
      });
    }
    return next.handle(request);

  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
import * as i0 from "./index.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./index.component";
import * as i3 from "@angular/router";
import * as i4 from "../services/userauthenticationservice.service";
import * as i5 from "ngx-toastr";
import * as i6 from "../interceptors/util";
var styles_IndexComponent = [i0.styles];
var RenderType_IndexComponent = /*@__PURE__*/ i1.ɵcrt({ encapsulation: 0, styles: styles_IndexComponent, data: {} });
export { RenderType_IndexComponent as RenderType_IndexComponent };
export function View_IndexComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_IndexComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-index", [], null, null, null, View_IndexComponent_0, RenderType_IndexComponent)), i1.ɵdid(1, 4308992, null, 0, i2.IndexComponent, [i3.ActivatedRoute, i4.UserauthenticationserviceService, i5.ToastrService, i3.Router, i6.Util], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IndexComponentNgFactory = /*@__PURE__*/ i1.ɵccf("app-index", i2.IndexComponent, View_IndexComponent_Host_0, {}, {}, []);
export { IndexComponentNgFactory as IndexComponentNgFactory };


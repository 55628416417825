<!-- <div class="toolbar" role="banner">
  <img width="40" alt="Angular Logo" src="../assets/images/image.ico"/>
  <span>Welcome {{name}},</span>
  <div class="spacer">
    <span style="float: right !important; margin: 2vh;">{{examname}}</span>
  </div>
</div>
<div class="" role="main">
  <div style="position: relative; top: 10vh;">
    <app-question-page-header></app-question-page-header>
  </div> -->
  <link *ngFor="let layoutFile of layoutFiles; let i = index" rel="preload" as="style" [href]="layoutFile.safeUrl" (load)="customCssLoaded(i)" (error)="customCssErrored(i)">
  <link *ngFor="let activeLayoutFile of activeLayoutFiles" rel="stylesheet" [href]="activeLayoutFile.safeUrl">
  <router-outlet></router-outlet>
  <div id="recaptcha-footer"></div>
  <!-- </div> -->

<div class="row justify-content-center login-container">
    <div class="col-lg-12 login-form-right">
        <div class="card bg-secondary col-lg-12">
          <h4 class="mt-2"> <a class="complaint" routerLink="/login"><i class='fas fa-arrow-left'></i>  {{language.backToLogin}}</a></h4>
            <h1 class="title text-center welcome-users"> {{language.allComplaints}}</h1>
            <div class="alert center" style="padding: 0.75em 0; text-align: center;">
                <p class="font-weight-bold" *ngIf="isLoading" style="color:white; background-color: cornflowerblue;">
                    <span><img style="height:40px;" src="/assets/img/spinner.gif" /></span>
                </p>

                <div class="table-responsive table-full-width">
                    <table class="table table-hover table-striped ">
                        <thead>
                            <tr>
                              <th ><h5>#</h5></th>
                              <th ><h5> {{language.complaintID}}</h5></th>
                              <th ><h5> {{language.examNo}}</h5></th>
                              <th ><h5> {{language.lastName}}</h5></th>
                              <th ><h5> {{language.status}}</h5></th>
                              <th ><h5> {{language.submittedDate}}</h5></th>
                            </tr>
                          </thead>
                          <tbody *ngIf="allComplaints?.length>0">
                            <tr *ngFor="let item of allComplaints; let i = index" style="cursor: pointer !important;" (click)="GotoComplaint(item)">
                              <th scope="row">{{i + 1 }}</th>
                              <td>{{item.complaintID}}</td>
                              <td>{{item.examNo}}</td>
                              <td>{{item.lastName}}</td>
                              <td>{{item.status}}</td>
                              <td>{{this.util.formatDate(item.submitedDate)}}</td>
                            </tr>
                          </tbody>
                      </table>
                      <h5 class="text-center" *ngIf="allComplaints?.length<=0"> {{language.noComplaint}}</h5>
                </div>
            </div>
        </div>
    </div>
</div>
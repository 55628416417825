<div class="row justify-content-center login-container">
    <div class="col-lg-12 login-form-right" *ngIf="complaintID">
        <div class="card bg-secondary col-lg-12">
            <h4 class="mt-2"> <a class="complaint" routerLink="/login"><i class='fas fa-arrow-left'></i>  {{language.backToLogin}}</a></h4>

            <h1 class="title text-center welcome-users">{{language.complaint}} {{complaintID}}</h1>
            <div class="alert center" style="padding: 0.75em 0; text-align: center;">
                <p class="font-weight-bold" *ngIf="isLoading" style="color:white; background-color: cornflowerblue;">
                    <span><img style="height:40px;" src="/assets/img/spinner.gif" /></span>
                </p>
            </div>
            <div class="card-body px-lg-3 py-lg-3">
                <div class="row mb-2">

                    <div class="col-md-6">
                        <label class="question">  {{language.examNo}}</label>
                        <input type="text" disabled [(ngModel)]="candidateComplaint.examNo">

                    </div>
                    <div class="col-md-6">
                        <label class="question">  {{language.lastName}}</label>
                        <input type="text" disabled [(ngModel)]="candidateComplaint.lastName">
                    </div>
                </div>

                <div class="mb-3" *ngIf="candidateComplaint">
                    <h3> {{language.selectExamComplaint}}</h3>
                    <div class="form-check mb-1">
                        <input class="form-check-input" type="radio" checked>
                        <label class="form-check-label text-muted ">
                            {{candidateComplaint.examDetail?.name}} | <span>{{
                                candidateComplaint.examDetail?.status }}</span> | <span>{{
                                this.util.formatDate(candidateComplaint.examDetail?.examdate) }}</span>
                        </label>
                    </div>
                </div>
                <div class="mb-3">
                    <h3> {{language.natureOfComplaint}}</h3>
                    <select id="" name="complaintCateory" class="form-control form-select form-select-lg" disabled>
                        <option>{{candidateComplaint.complaintCategory.categoryText}}</option>
                    </select>
                </div>
                <!-- <div class="mb-3" *ngIf="candidateComplaint.additonalResponse">
                    <div class="mb-1">
                        <label class="question" disabled> Complaint Details</label>
                        <textarea class="form-control mt-1" placeholder="Leave Additional Comments here" rows="10"
                            autocomplete id="additonalRespose" [(ngModel)]="candidateComplaint.additonalResponse"
                            name="additonalRespose" id="additonalRespose" disabled></textarea>
                    </div>
                </div> -->
                <div class="mb-3">
                    <h5 class="text-muted text-right"><span
                            [ngClass]="{'badge-pending':candidateComplaint.status!='CLOSED' ,'badge-closed':candidateComplaint.status=='CLOSED' }">
                            {{language.status}}: {{candidateComplaint.status }} </span>  {{language.submittedDate}}:
                        {{this.util.formatDate(candidateComplaint.submitedDate)}}</h5>
                </div>
                <hr>
                <!-- <div class="mb-3" *ngIf="candidateComplaint.adminResponse">
                    <h5 class="text-muted text-right">Response Date:
                        {{this.util.formatDate(candidateComplaint.respondDate )}}</h5>
                    <label class="question" disabled>Reply:</label>

                    <div [innerHTML]="candidateComplaint.adminResponse"></div>
                </div> -->

                <div class="form-footer d-flex" *ngIf="candidateComplaint.status!='CLOSED'">
                    <button type="button" id="prevBtn" class="mr-2" (click)="closeBtnClick()"> {{language.closeComplaints}}</button>
                    <button type="button" id="nextBtn" (click)="showReply = !showReply">{{showReply? language.closeReply: language.replyComplaint}}</button>
                </div>

                <div class="m-3" *ngIf="showReply">
                    <div class="mb-1">
                        <h3 class="qustion"> {{language.reply}}:</h3>
                        <textarea class="form-control" [placeholder]="language.enterResponseDetails" rows="10"
                             autocomplete id="respose" [(ngModel)]="respose" name="respose"
                            id="respose"></textarea>
                    </div>
                    <button type="button" id="nextBtn" (click)="submitBtnClick()">{{language.submit}}</button>

                </div>

                  <legend class="conversation-legend"> {{language.supportDiscussion}}</legend>
                <div class="response-container" #responseContainer>
                 
                    <div *ngFor="let response of candidateComplaint.responses">
                      <div class="row mb-3">
                        <div [ngClass]="{'col-8': response.isAdminResponse, 'col-8 offset-4': !response.isAdminResponse}">
                          <div class="response-content">
                            <small class="text-muted">
                             <b> {{ response.isAdminResponse ? language.admin: language.you }}</b>
                            </small>
                            <div [innerHTML]="response.response" class="p-3 response-box"></div>
                            <small class="text-muted">{{this.util.formatDate(response.responseDate) }}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
               
            </div>
        </div>
    </div>
</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { QuestionComponent } from './question/question.component';
import { AuthguardGuard } from './auth/authguard.guard';
import { UserexaminationsComponent } from './userexaminations/userexaminations.component';
import { LogoutComponent } from './logout/logout.component';
import { LoginLayoutComponent } from './login-layout/login-layout.component';
import { ExamLayoutComponent } from './exam-layout/exam-layout.component';
import { QuestionlayoutComponent } from './questionlayout/questionlayout.component';
import { SubmitComponent } from './submit/submit.component';
import { IndexComponent } from './index/index.component';
import { RegisterComponent } from './register/register.component';
import { P404Component } from './p404/p404.component';
import { SebinstructionpageComponent } from './sebinstructionpage/sebinstructionpage.component';
import { VerifyEmailComponent } from './register/verify-email/verify-email.component';
import { QuitSEBComponent } from './quit-seb/quit-seb.component';
import { SelectMediaComponent } from './meeting/select-media/select-media.component';
import { MeetingComponent } from './meeting/meeting/meeting.component';
import { ComplaintComponent } from './complaint/complaint.component';
import { ViewComplaintComponent } from './complaint/view-complaint/view-complaint.component';
import { AllComplaintComponent } from './complaint/all-complaint/all-complaint.component';
import { SystemCheckComponent } from './system-check/system-check.component';



const routes: Routes = [
  {
    path: '', component: IndexComponent, pathMatch: "full"//redirectTo: "access/login", pathMatch: "full"//
  },
  {
    path: 'exam',
    canActivateChild: [AuthguardGuard],
    component: ExamLayoutComponent,
    children: [
      {
        path: 'question',
        component: QuestionlayoutComponent,

      },
      {
        path: 'submit',
        component: SubmitComponent,

      },
      {
        path: 'userexams',
        component: UserexaminationsComponent,
      },
      { path: 'join/:meetingid', component: SelectMediaComponent },
      { path: 'meeting/:meetingid', component: MeetingComponent, children: [
        { path: 'settings', component: SelectMediaComponent, data: { inMeeting: true} }
      ] },
    ]
  },
  { path: "login", redirectTo: "access/login", pathMatch: "full" },
  { path: "register/:examid", redirectTo: "access/register/:examid", pathMatch: "full" },
  { path: "verify/:jwt", redirectTo: "access/verify/:jwt", pathMatch: "full" },
  { path: "sebinstruction", redirectTo: "access/sebinstruction", pathMatch: "full" },
  { path: "complaint", redirectTo: "access/complaint", pathMatch: "full" },
  { path: "complaint/:id/:examno", redirectTo: "access/complaint/:id/:examno", pathMatch: "full" },
  { path: "complaints/:examno/:lastname", redirectTo: "access/complaints/:examno/:lastname", pathMatch: "full" },
  { path: "quitseb", component: QuitSEBComponent },
  { path: "system-check", redirectTo: "access/system-check", pathMatch: "full" },

  { path: "logout", component: LogoutComponent },
  {
    path: "access", component: LoginLayoutComponent,
    children: [
      { path: "login", component: LoginComponent },
      { path: "register/:examid", component: RegisterComponent },
      { path: "verify/:jwt", component: VerifyEmailComponent },
      { path: "sebinstruction", component: SebinstructionpageComponent },
      { path: "complaint", component: ComplaintComponent },
      { path: "complaint/:id/:examno", component: ViewComplaintComponent },
      { path: "complaints/:examno/:lastname", component: AllComplaintComponent },
      { path: "system-check", component: SystemCheckComponent },

      
    ]

  },
  { path: '**', component: P404Component }
];
const routerOptions: ExtraOptions = {
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload',
    relativeLinkResolution: 'legacy'
};

@NgModule({

  declarations: [],
  imports: [
    RouterModule.forRoot(routes, routerOptions)
  ],
  exports: [
    RouterModule
  ],

})

export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-quit-seb',
  templateUrl: './quit-seb.component.html',
  styleUrls: ['./quit-seb.component.css']
})
export class QuitSEBComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class AlertService{

        CALL_TYPE = {
            SIMPLE_PAGE_LOAD : 0,
            SIMPLE_PAGE_LOAD_GET_CONTENT : 1,
            POST_FORM_DATA : 2
        }

        notificationMessageBody:string;
        notificationMessageHeader:string;
        notificationTimeOut = 5000;
        currentNotificationType:number;

        constructor(){

        }
}


<div class="row justify-content-center login-container">

    <div class="col-lg-12 login-form-right">

        <div class="container">
            <h1 class="title text-center welcome-users">{{language.proctoringSystemCheck}}</h1>
            <div class="alert center" style="padding: 0.75em 0; text-align: center;">
                <p class="font-weight-bold" *ngIf="isLoading" style="color:white; background-color: cornflowerblue;">
                    <span><img style="height:40px;" src="/assets/img/spinner.gif" /></span>
                </p>
            </div>

            <ul>
                <li>
                    <i *ngIf="cameraAvailable" class="fa fa-check-square-o  text-green"></i>
                    <i *ngIf="!cameraAvailable" class="fa fa-square-o text-danger"></i>

                    {{language.camera}}: <strong>{{ (cameraAvailable && cameraResponse=='Yes') ? language.available :
                        language.notAvailable
                        }}</strong>
                    <i *ngIf="cameraAvailable && cameraResponse=='Yes'" class="fas fa-video ml-3 text-green"></i>
                    <i *ngIf="!cameraAvailable|| !cameraResponse || cameraResponse=='No'"
                        class="fas fa-video-slash ml-3 text-danger"></i>
                </li>

                <li> <i *ngIf="speakerAvailable" class="fa fa-check-square-o  text-green"></i>
                    <i *ngIf="!speakerAvailable" class="fa fa-square-o text-danger"></i>
                    {{language.speaker}}: <strong>{{ (speakerAvailable && speakerResponse=='Yes') ? language.available :
                        language.notAvailable
                        }}</strong>
                    <i *ngIf="speakerAvailable && speakerResponse=='Yes'" class="fas fa-volume-up ml-3 text-green"></i>
                    <i *ngIf="!speakerAvailable || !speakerResponse || speakerResponse=='No'"
                        class="fas fa-volume-mute ml-3 text-danger"></i>
                </li>
                <li> <i *ngIf="microphoneAvailable" class="fa fa-check-square-o  text-green"></i>
                    <i *ngIf="!microphoneAvailable" class="fa fa-square-o text-danger"></i>
                    {{language.microphone}}: <strong>{{
                        microphoneAvailable
                        ? language.available : language.notAvailable }}</strong>
                    <i *ngIf="microphoneAvailable" class="fas fa-microphone-alt ml-3 text-green"></i>
                    <i *ngIf="!microphoneAvailable" class="fas fa-microphone-alt-slash ml-3 text-danger"></i>
                </li>
                <li> <i *ngIf="canRecord" class="fa fa-check-square-o  text-green"></i>
                    <i *ngIf="!canRecord" class="fa fa-square-o text-danger"></i> {{language.recording}}: <strong>{{
                        canRecord
                        ? language.available : language.notAvailable }}</strong>
                    <i *ngIf="canRecord" class="fas 	fas fa-play-circle ml-3 text-green"></i>
                    <i *ngIf="!canRecord" class="fas 	fas fa-stop-circle ml-3 text-danger"></i>
                </li>
                <li> <i *ngIf="osVersion&& osVersion!=language.unknown" class="fa fa-check-square-o  text-green"></i>
                    <i *ngIf="!osVersion || osVersion==language.unknown" class="fa fa-square-o text-danger"></i>
                    {{language.osVersion}}: <strong>{{
                        osVersion
                        }}</strong>
                </li>
                <li> <i *ngIf="browserVersion&& browserVersion!=language.unknown"
                        class="fa fa-check-square-o  text-green"></i>
                    <i *ngIf="!browserVersion || browserVersion==language.unknown"
                        class="fa fa-square-o text-danger"></i> {{language.browserVersion}}: <strong>{{ browserVersion
                        }}</strong>
                </li>
                <li> <i *ngIf="isDesktopDevice" class="fas fa fa-check-square-o  text-green"></i>
                    <i *ngIf="!isDesktopDevice" class="fa fa-square-o text-danger"></i> {{language.deviceType}}:
                    <strong>{{deviceType}}</strong>
                </li>


            </ul>
            <div class="mb-3">
                <button class="btn btn-primary" (click)="runCheck(true)">{{language.runCheck}}</button>
                <button class="btn btn-primary" (click)="continueClicled()"
                    *ngIf="showContinue">{{language.backToLogin}}</button>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="systemcheckModal" data-backdrop="static" aria-labelledby="systemcheckModalLabel"
                aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="systemcheckModalLabel">{{language.mediaChecks}}</h5>
                            <!-- <button type="button" class="close h3" data-dismiss="modal"
                                aria-label="Close" (click)="closeCheck()">&times;</button> -->
                        </div>
                        <div class="modal-body">
                            <h3>{{language.cameraCheck}}:</h3>
                            <div class="camera-container">
                                <video #videoElement autoplay></video>
                                <canvas #canvasElement style="display: none;"></canvas>
                                <select class="form-control form-select" [(ngModel)]="cameraResponse" *ngIf="!camError">
                                    <option value=undefined selected disabled>{{language.canSeeSelf}}</option>
                                    <option value="Yes">{{language.yes}}</option>
                                    <option value="No">{{language.no}}</option>
                                </select>
                                <p class="text-danger">{{camError}}</p>

                                <!-- <button (click)="captureImage()">Capture Image</button> -->
                                <!--                               
                                <div *ngIf="capturedImage">
                                  <h3>Captured Image:</h3>
                                  <img [src]="capturedImage" alt="Captured Image" />
                                </div> -->

                            </div>
                            <h3>{{language.microphoneCheck}}:</h3>
                            <p class="text-danger">{{micError}}</p>
                            <div *ngIf="isMicrophoneActive" class="mb-3">
                                <div class="volume-bar">
                                    <div [style.width.%]="microphoneVolume" class="volume-indicator"></div>
                                </div>
                            </div>

                            <h2>{{language.speakerCheck}}</h2>
                            <button (click)="checkSpeakers()"
                                class="btn btn-secondary">{{language.playTestSound}}</button>
                            <div *ngIf="isPlaying">
                                <p>{{language.playingTestSound}}...</p>
                            </div>
                            <select class="form-control form-select mt-2" [(ngModel)]="speakerResponse"
                                *ngIf="checkSpeakerCliked">
                                <option value=undefined selected disabled>{{language.canHearBeepSound}}</option>
                                <option value="Yes">{{language.yes}}</option>
                                <option value="No">{{language.no}}</option>
                            </select>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeCheck()"
                                [disabled]="!speakerResponse || !cameraResponse">{{language.continue}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="warnings.length > 0" class="alert alert-warning">
                <ul>
                    <li *ngFor="let warning of warnings">{{ warning }}</li>
                </ul>
            </div>
        </div>
    </div>
</div>
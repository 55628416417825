export class Exam {
  id: number = 0;
  examid: number = 0;
  name: string = '';
  status: string = '';
  examdate: Date;
  examtype: string;
  duration: number = 0;
  useproctor: boolean = false;
  selfschedule: boolean = false;
  allowreschedule: boolean = false;
  allowrescheduleAfter: boolean = false;
  showscorewhenfinished: boolean = false;
  examscorestring: string;
  examstartedbyadmin: boolean = false;
  canstart: boolean = false;
  requireadminstart: boolean = false;
  proctorerror: string;
  passporterror: string;
  attempt: number = 0;
  skipAIPassportCheck = false;
  disablePassportUpload = false;
  useSEB = false;
  useCaptcha = true;
  liteMode = false;
  interviewaccesscode: string = '';
  canRetake = false;
}


export class ExamQuestions {

}
export class ExamDetails {
  scheduleid: number = 0
  examid: number = 0
  examname: string = ""
  examtype: string = ""
  checkfocusloss: boolean = false
  focuspenalty: string = ""
  nooffocusloss: number = 0
  durationofloss: number = 0
  reducetimelossduration: number = 0
  useproctor: boolean = false
  showscorewhenfinished: boolean = false
  noofquestions: number = 0
  examstartedbyadmin: boolean = false
  requireadminstart: boolean = false
  liteMode: boolean = false
  useCaptcha = true;
  useSEB: boolean = false

}

export class Instructions {
  webtestHTML: string = ""
  companyHTML: string = ""
  examHTML: string = ""
  company: string = ""
}

import { ILanguage } from "./ILanguage";

export class French implements ILanguage {
    systemCheck: string = "Exécuter la vérification du système";
    desktop: string = "Bureau";
    mobile: string = "Mobile";
    unknown: string = "Inconnu";
    selectLanguage: string = "Sélectionner la langue";
    languageName = "Français";
    submitConfirmationMessage: string = "Êtes-vous sûr de vouloir soumettre ? Cela mettra fin à l'examen";
    backToLogin: string = "Retour à la connexion";
    allComplaints: string = "Toutes les plaintes";
    complaintID: string = "ID de la plainte";
    examNo: string = "Numéro d'examen";
    lastName: string = "Nom de famille";
    firstName: string = "Prénom";
    status: string = "Statut";
    submittedDate: string = "Date de soumission";
    noComplaint: string = "Aucune plainte trouvée";
    complaint: string = "Plainte";
    selectExamComplaint: string = "Veuillez sélectionner l'examen pour lequel vous souhaitez déposer une plainte.";
    natureOfComplaint: string = "Veuillez sélectionner la nature de votre plainte.";
    closeComplaints: string = "Fermer la plainte";
    closeReply: string = "Fermer la réponse";
    replyComplaint: string = "Répondre à la plainte";
    reply: string = "Réponse";
    enterResponseDetails: string = "Entrez les détails de votre réponse";
    submit: string = "Soumettre";
    supportDiscussion: string = "Discussion de support";
    admin: string = "Admin";
    you: string = "Vous";
    complaintsClosed: string = "Plaintes fermées";
    replySent: string = "Réponse envoyée";
    candidateInformation: string = "Informations sur le candidat";
    viewAllComplaints: string = "Voir toutes les plaintes";
    examinationSelection: string = "Sélection de l'examen";
    complaintsDisabled: string = "Aucun examen trouvé ou les plaintes ont été désactivées pour cet examen";
    complaintCategory: string = "Catégorie de plainte";
    selectOption: string = "Sélectionner une option";
    complaintDetails: string = "Détails de la plainte";
    solvedYourIssue: string = "Le processus ci-dessus a-t-il résolu votre problème ?";
    yes: string = "Oui";
    no: string = "Non";
    enterComplaintDetails: string = "Entrez les détails de votre plainte";
    previous: string = "Précédent";
    cancel: string = "Annuler";
    next: string = "Suivant";
    limitOf: string = "Limite de";
    wordsMaximum: string = "mots maximum";
    welcome: string = "Bienvenue";
    logout: string = "Déconnexion";
    passportSection: string = "SECTION DU PASSEPORT";
    maxFileUpload1mb: string = "Taille maximale de téléchargement de fichier : 1 Mo";
    takePicture: string = "Prendre une photo";
    clearFaceInstruction: string = "Veuillez vous assurer que votre visage est clairement visible à tout moment.";
    brightLightInstruction: string = "Veuillez vous assurer qu'il n'y a pas de lumière vive ou de fenêtres derrière vous.";
    requestAttention: string = "Demander de l'attention";
    uploadPassport: string = "Télécharger le passeport";
    unableToConnectToServer: string = "Impossible de se connecter au serveur";
    waitForProctorOk: string = "Veuillez attendre que le surveillant vous contacte";
    passportChangeErr: string = "Le passeport ne peut pas être modifié après le début de l'examen";
    uploadSuccessful: string = "Téléchargement réussi";
    savePassport: string = "Enregistrer le passeport";
    changePassport: string = "Changer de passeport";
    examinationFeedBack: string = "Retour d'information sur l'examen";
    happy: string = "Content";
    neutral: string = "Neutre";
    unhappy: string = "Mécontent";
    close: string = "Fermer";
    problemCategories: string = "Catégories de problèmes";
    othersProblemCategories: string = "Autres (veuillez fournir des détails)";
    login: string = "Connexion";
    username: string = "Nom d'utilisateur";
    password: string = "Mot de passe";
    makeComplaint: string = "Déposer une plainte";
    loggedOut: string = "Vous êtes déconnecté";
    userMis_match: string = "Incompatibilité de l'utilisateur. Accès non autorisé";
    loginToTakeExam: string = "Connectez-vous pour passer l'examen";
    aboutUs: string = "À propos de nous";
    //
    error: string = "Erreur";
    somethingWentWrong: string = "Oups ! Quelque chose s'est mal passé.";
    pleaseConatctAdministrator: string = "Veuillez contacter l'administrateur";
    instructions: string = "Instructions";
    question: string = "Question";
    pleaseHold: string = "VEUILLEZ PATIENTER";
    examHasBeenPaused: string = "VOTRE EXAMEN A ÉTÉ MIS EN PAUSE";
    reason: string = "RAISON";
    willNotLoseTime: string = "PENDANT CETTE PÉRIODE, VOUS NE PERDREZ PAS DE TEMPS CAR VOTRE MINUTEUR D'EXAMEN A ÉTÉ MIS EN PAUSE";
    userSessionNotFound: string = "Session utilisateur introuvable";
    networkConnectionLost: string = "Connexion réseau perdue. Tentative de reconnexion";
    multipleLoginsDetected: string = "Connexions multiples détectées";
    unauthorizedAccess: string = "Accès non autorisé";
    penalizedWarning: string = "Vous n'êtes pas autorisé à quitter la page de l'examen. Le faire entraînera une pénalité";
    youHaveLost: string = "Vous avez perdu";
    leavingExamPage: string = "minutes en tant que pénalité pour avoir quitté la page de l'examen";
    finishAndSubmit: string = "Terminer/Soumettre";
    questionHasBeen: string = "La question a été";
    verifyingEmail: string = "Vérification de l'e-mail";
    registration: string = "Inscription";
    emailAddress: string = "Adresse e-mail";
    verifyEmailAddress: string = "Vérifiez l'adresse e-mail";
    otherNames: string = "Autres noms";
    phoneNumber: string = "Numéro de téléphone";
    gender: string = "Genre";
    selectGender: string = "Sélectionner le genre";
    male: string = "Homme";
    female: string = "Femme";
    scheduleDate: string = "Date prévue";
    scheduleTime: string = "Heure prévue";
    selectTime: string = "Sélectionner l'heure";
    noAvailabeTime: string = "Aucune heure disponible";
    noAvailabeDate: string = "Aucune date disponible";
    register: string = "S'inscrire";
    registrationSuccessful: string = "Inscription réussie";
    yourRegistrationFor: string = "Votre inscription pour";
    wasSuccessful: string = "a été réussie.";
    yourExaminationNumberIs: string = "Votre numéro d'examen est";
    checkYourEmailForMoreInformation: string = "Vérifiez votre e-mail pour plus d'informations sur l'examen.";
    imageSizeIsGreaterthan1mb: string = "La taille de l'image est supérieure à 1 Mo.";
    invalidImageFormat: string = "Format d'image invalide";
    pleaseSelectGender: string = "Veuillez sélectionner un genre";
    pleaseFillExaminationSchedule: string = "Veuillez remplir les détails de la programmation de l'examen";
    pleaseUploadPassportPicture: string = "Veuillez télécharger la photo du passeport";
    registrationComplete: string = "Inscription terminée";
    verificationEmailSent: string = "Un e-mail de vérification a été envoyé à";
    invalidEmail: string = "E-mail invalide";
    emailVerified: string = "E-mail vérifié";
    invalidCode: string = "Code invalide";
    unavailableDateSelected: string = "Date indisponible sélectionnée";
    examPlatformUsageInst: string = "INSTRUCTIONS D'UTILISATION DE LA PLATEFORME D'EXAMEN";
    useSafeExamBrowser: string = "Pour passer cet examen, vous devrez utiliser le navigateur sécurisé (SEB). Suivez les instructions ci-dessous.";
    installSafeExamBrowser: string = "Si vous ne l'avez pas encore installé, utilisez le lien ci-dessous pour le télécharger.";
    currentSEBVersion: string = "Assurez-vous d'utiliser la dernière version (3.7.1 mise à jour le 11 septembre 2024)";
    skipStep3: string = "Si c'est déjà fait, passez à l'étape 3.";
    sebDownloadLink: string = "Lien de téléchargement de SEB";
    installTheSoftware: string = "Une fois téléchargé, installez le logiciel.";
    onceSEBIsInstalled: string = "Une fois SEB installé, cliquez";
    here: string = "ici";
    downloadSEBexamfileforyourexam: string = "pour télécharger le fichier SEB pour votre examen.";
    youshouldseefile: string = "Vous devriez voir un fichier qui ressemble à ceci";
    double_clickdownloadedfile: string = "Double-cliquez sur le fichier téléchargé pour lancer votre examen.";
    confirmInstallSafeExamBrowser: string = "Remarque : Si le fichier d'examen SEB ne fonctionne pas, veuillez confirmer que vous avez correctement installé SEB.";
    unabletoconnecttoserver: string = 'Impossible de se connecter au serveur';
    connectionError: string = 'Erreur de connexion';
    unknownserversideerror: string = 'Erreur côté serveur inconnue';
    youhavebeenpreventedfromstarting: string = "Vous avez été empêché de commencer l'examen parce que";
    connectiontomediaserverlost: string = 'Connexion au serveur média perdue. Veuillez vous reconnecter';
    verificationError: string = 'Une erreur de vérification s’est produite sur votre système. Si ce problème persiste, actualisez la page ou redémarrez votre navigateur';
    unexpectedCondition: string = 'Condition inattendue. exception markanswerasync';
    loading: string = 'Chargement';
    submitExamination: string = 'SOUMETTRE L’EXAMEN';

    submitting: string = 'Soumission en cours';
    yourScore: string = 'Votre score est';
    startNewExam: string = 'Commencer un nouvel examen';
    takeExam: string = 'Passer l’examen';
    join: string = 'Rejoindre';
    minutes: string = 'minutes';
    start: string = 'Commencer';
    continue: string = 'Continuer';
    scheduleExam: string = 'Programmer un examen';
    pleaseWait: string = 'Veuillez patienter';
    finished: string = 'Terminé';
    timeElapsed: string = 'Temps écoulé';
    disqualified: string = 'Disqualifié';
    stopped: string = 'Arrêté';
    rescheduleExam: string = 'Reprogrammer l’examen';
    webtestInstruction: string = 'INSTRUCTIONS D’UTILISATION';
    agreeToInstructions: string = 'Je reconnais avoir lu et compris les instructions ci-dessus';
    startExam: string = 'Commencer l’examen';
    examSchedule: string = 'Programmation des examens';
    selectDate: string = 'Sélectionner une date';
    schedule: string = 'Programmer';
    examScheduled: string = 'Examen programmé. Veuillez vérifier votre e-mail';
    examinationList: string = 'Liste des examens';
    scheduledExaminations: string = 'Examens programmés';
    ensureyourfacefillsthecircle: string = 'Veuillez vous assurer que votre visage remplit la majeure partie du cercle';
    capturePhoto: string = 'Capturer une photo';
    retake: string = 'Reprendre';
    upload: string = 'TÉLÉCHARGER';
    youhavenooutputvideodevice: string = 'Aucun appareil vidéo de sortie détecté';
    errorloadingcustomcssfile: string = 'Erreur lors du chargement du fichier CSS personnalisé';
    noExamsFound: string = 'Aucun examen trouvé';
    multipleChoice: string = "Choix multiple";
    interview: string = "Entretien";
    essay: string = "Dissertation";
    flagged: string = "Signalé";
    unflagged: string = "Non signalé";
    duplicateCameratyprDetected: string = "Plusieurs types de caméras détectés";
    errorCheckingDuplicateCameras: string = "Erreur lors de la vérification des caméras en double";
    itRecommendedUseDesktopDevice: string = "Il est recommandé d’utiliser un appareil de bureau pour ce système de surveillance.";
    virtualMachineDetected: string = "Machine virtuelle détectée et en cours d’exécution.";
    noWorkingCamerafound: string = "Aucune caméra fonctionnelle trouvée.";
    failedToAccessCamera: string = "Échec de l'accès à la caméra :";
    noWorkingMicrophoneFound: string = "Aucun microphone fonctionnel trouvé.";
    failedToAccessMicrophone: string = "Échec de l'accès au microphone :";
    failedToPlaySound: string = "Échec de la lecture du son :";
    failedToEnumerateDevices: string = "Échec de l'énumération des appareils : ";
    developerToolsAreOpen: string = "Les outils de développement sont ouverts";
    available: string = "Disponible";
    notAvailable: string = "Indisponible";
    proctoringSystemCheck: string = "Vérification du système de surveillance";
    camera: string = "Caméra";
    speaker: string = "Haut-parleur";
    microphone: string = "Microphone";
    recording: string = "Enregistrement";
    osVersion: string = "Version du système d'exploitation";
    browserVersion: string = "Version du navigateur";
    deviceType: string = "Type d'appareil";
    virtualMachine: string = "Machine virtuelle";
    safeExamBrowser: string = "Navigateur sécurisé (SEB)";
    runCheck: string = "Lancer la vérification";
    mediaChecks: string = "Vérifications des médias";
    cameraCheck: string = "Vérification de la caméra";
    microphoneCheck: string = "Vérification du microphone";
    speakerCheck: string = "Vérification du haut-parleur";
    playTestSound: string = "Lire le son de test";
    playingTestSound: string = "Lecture du son de test";
    unknownBrowser: string = "Navigateur inconnu";
    unknownVersion: string = "Version inconnue";
    notUsed: string = "Non utilisé";
    used: string = "Utilisé";
    detected: string = "Détecté";
    notDetected: string = "Non détecté";
    canHearBeepSound: string = "Avez-vous entendu le son ?";
    canSeeSelf: string = "Pouvez-vous vous voir clairement ?";
    audioNotAvailablePlease: string = "L’audio n’est pas disponible. Veuillez vérifier le volume ou réessayer.";
    cameraNotAvailablePlease: string = "La caméra n’est pas disponible. Veuillez vérifier les paramètres ou réessayer.";
    retakeExam: string = "Reprendre l’examen";
    reasonForRetake: string = "Raison pour reprendre l’examen";

}
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Util } from '../interceptors/util';
import { loginDetails } from '../model'
import { CbtserviceService } from '../services/cbtservice.service';
import { ProctoringService } from '../services/proctoring.service';
import { UserauthenticationserviceService } from '../services/userauthenticationservice.service';
import { LanguageService } from '../services/language.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  logininfo: loginDetails;
  username: string;
  password: string
  isLoading: boolean = false;
  logourl: any;
  externaltoken: string;
  externalportalname: string;
  fromexternalauth: string;//this is set to true when redirected from logout. it is used to hide the login form when it should't be used...such users shoud login from their external website
  seb: any;
  constructor(public userauthservice: UserauthenticationserviceService, public toastr: ToastrService, public activatedroute: ActivatedRoute, public router: Router, private proctorservice: ProctoringService, public util: Util, private cbtservice: CbtserviceService,private languageService: LanguageService) { }
  get language(){
    return this.languageService.language;
  }
  async ngOnInit() {
    //     var req = new XMLHttpRequest();
    // req.open('GET',document.location.href, false);
    // req.send(null);
    // var headers = req.getAllResponseHeaders().toLowerCase();
    // alert(headers);
    //debugger;
    console.log("okayy")
    await this.proctorservice.stopProctor('Login Page Init');
    var unauthorized = this.activatedroute.snapshot.queryParams.unauthorized
    if (unauthorized) {
      this.toastr.error(this.language.userMis_match);
    }
    //debugger
    const subdomain = this.util.getSubdomain();
    if (subdomain) {
      this.isLoading = true;
      var data: any = await this.userauthservice.getsubdomaincompanyinfo(subdomain).toPromise().catch(err => this.toastr.error(err)).finally(() => this.isLoading = false)
      this.logourl = data ? data.logourl : "";
    }

    this.externaltoken = this.activatedroute.snapshot.queryParams.externaltoken;
    console.log("externaltoken is " + this.externaltoken)

    this.externalportalname = this.activatedroute.snapshot.queryParams.externalportalname;

    //debugger;
    this.fromexternalauth = this.activatedroute.snapshot.queryParams.fromexternalauth;
    this.seb = this.activatedroute.snapshot.queryParams.seb;
    console.log("seb is " + this.seb);
    if(this.externaltoken){
      this.loginclicked();
    }
  }


  async loginclicked() {

    let token = "";
    if (this.externaltoken) {
      this.isLoading = true;
      const subdomain = this.util.getSubdomain();
      const data: any = await this.userauthservice.externalUserAuthentication(this.externaltoken, subdomain, this.externalportalname).toPromise().catch(err => { this.cbtservice.showHttpError(err); this.isLoading = false; });
      token = data.token;
      //this.userauthservice.setAuthenticatedUserToken(data.token, true);
      //this.goToExamsPage();
    }
    else {
      const logininfo = {
        username: this.username,
        password: this.password
      }

      // console.log(logininfo.username);
      this.isLoading = true;
      const data: any = await this.userauthservice.userAuthentitcation(logininfo.username, logininfo.password).toPromise().catch(err => { this.cbtservice.showHttpError(err); this.isLoading = false; });
      token = data.token;
    }
    this.userauthservice.setAuthenticatedUserToken(token, false, this.seb);
    //debugger;
    if (this.seb) {
      var valid = await this.userauthservice.checksebconfig(this.seb).toPromise().catch(err => {
        this.cbtservice.showHttpError(err);
        this.isLoading = false;
        this.router.navigate(["/logout"]);
      });
      if (!valid) {
        this.router.navigate(["/logout"]);
      }
      this.proctorservice.hideSEBPowerButton(this.username);
    }
    this.goToExamsPage();

  }

  private goToExamsPage() {
    this.isLoading = false;
    this.userauthservice.loggedOut = false;
    if (this.activatedroute.snapshot.queryParams.url) {
      this.router.navigateByUrl(this.activatedroute.snapshot.queryParams.url);
    }
    else {
      this.router.navigate(['/exam/userexams']);
    }
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ExamService } from '../services/exam.service';
import { ProctoringService } from '../services/proctoring.service';
import { UserauthenticationserviceService } from '../services/userauthenticationservice.service';

import { environment } from 'src/environments/environment';
import { QuestionserviceService } from '../services/questionservice.service';
import { CbtserviceService } from '../services/cbtservice.service';
import { ExamfeedbackComponent } from '../examfeedback/examfeedback.component';
import { CurrentUser } from '../models/CurrentUser';
import { ExamDetails } from '../models/Exam';
import { LanguageService } from '../services/language.service';
@Component({
  selector: 'app-submit',
  templateUrl: './submit.component.html',
  styleUrls: ['./submit.component.css']
})
export class SubmitComponent implements OnInit {
  examno: string;
  thisurl = location.origin + '/';
  score: any;
  examination: ExamDetails;
  onblurfunction;
  onfocusfunction;
  countdown: any;
  lossactionmsg: string = "";
  losscount: number = 0;
  focuslosswarningmessage: string = ""
  lostfocus: boolean = false
  constructor(public activatedroute: ActivatedRoute, public userauth: UserauthenticationserviceService, public router: Router, public examservice: ExamService, public toastr: ToastrService,public questionservice:QuestionserviceService, private proctorservice: ProctoringService, private cbtservice: CbtserviceService, private languageService: LanguageService) { }
  scheduleid: any
  msg: any
  postmsg: any
  isloading: boolean = false;
  submitting: boolean = false;
  @ViewChild('feedmodal') private feedmodal: ExamfeedbackComponent;

  get language(){
    return this.languageService.language;
  }

  async ngOnInit() {
    //debugger
    this.scheduleid = this.activatedroute.snapshot.queryParams.scheduleid
    this.isloading = true;

    try{
      const user: CurrentUser = await this.userauth.getCurrentUser();
      if (user) {
        this.examno = user.username;
      }
      else if (this.userauth.getCurrentUserToken()) {
        this.userauth.userLogout();
      }
      if (!this.activatedroute.snapshot.queryParams.url) {
        await this.proctorservice.stopProctor('Submit Page Init');
        this.isloading = true;
        const response: any = await this.examservice.getandupdateexamstatus(this.scheduleid,false);
        this.msg = response?.statusmsg;
        this.score = response?.score
        this.postmsg = response?.postmsg;

      }
      this.examination = await this.examservice.getexamwithscheduleid(this.scheduleid);

      if (this.examination.checkfocusloss && !this.onblurfunction && !this.msg/* && admintestlogin != "True"*/) {
        this.onblurfunction = this.onBlur.bind(this);//have to do it this way because if i just set the event listener to this.setTileSize, the "this" becomes window
        this.onfocusfunction = this.onFocus.bind(this);
        window.addEventListener('focus', this.onfocusfunction);
        window.addEventListener('blur', this.onblurfunction);
      }
      this.isloading = false;
    }
    catch(error)
    {
      console.error(error);
      this.cbtservice.showHttpError(error); 
      this.isloading = false;
    }
  }

  noclicked() {
    this.questionservice.previousquestiondata = null;
    this.questionservice.nextquestiondata = null;
    if (this.activatedroute.snapshot.queryParams.url) {
      this.router.navigateByUrl(this.activatedroute.snapshot.queryParams.url);
    }

    else {
      this.router.navigate(['/exam/question'], { queryParams: { scheduleid: this.scheduleid, questionno: 1 } });
    }
  }
  async yesclicked() {
    this.feedmodal.open();
    try{
      this.submitting = true;
      
      var response: any = await this.examservice.submitexam(this.scheduleid,this.examination.liteMode).catch(err => this.cbtservice.showHttpError(err));
      if (response.statusmsg){
        this.questionservice.clearQuestionStorage();
      }
      await this.proctorservice.stopProctor('Exam Submit Clicked');
      this.msg = response.statusmsg;
      this.score = response.score
      this.postmsg = response.postmsg;
    }
    finally{
      this.submitting = false;
    }
  }

  // @OnPageVisibilityChange()
  // logWhenPageVisibilityChange(visibilityState: AngularPageVisibilityStateEnum): void {
  //   if (AngularPageVisibilityStateEnum[visibilityState]
  //     === AngularPageVisibilityStateEnum[AngularPageVisibilityStateEnum.HIDDEN] && this.msg) {
  //     console.log('OnPageVisibilityChange => hidden');
  //   }
  // }

  newexam() {
    window.location.href = `${this.thisurl}exam/userexams`;
  }

  ngOnDestroy(): void {
    if (this.onblurfunction) {
      window.removeEventListener('focus', this.onfocusfunction);
      window.removeEventListener('blur', this.onblurfunction);
    }
  }

  onFocus() {
    clearTimeout(this.countdown);
    console.log('OnPageVisibilityChange => visible');
    if (this.lostfocus && this.focuslosswarningmessage != '') {
      this.toastr.warning(this.focuslosswarningmessage);
    }
  };
  
  onBlur() {
    if (this.examination.checkfocusloss && !this.proctorservice.chatopened) {
      let audio = new Audio();
      audio.src = "/assets/beep.mp3";
      audio.loop = false;
      audio.load();
      audio.play();
      console.log("audio played");
      const mythis = this;
      this.countdown = setTimeout(() => {
        //debugger;
        if (!mythis.proctorservice.chatopened) {
          this.setres();
        }
        // if ((this.examination.nooffocusloss - this.losscount) === 1) {
        //   audio.play();
        //   console.log("audio played")
        // }
      }, 1000 * this.examination.durationofloss);
    }
  } 
  
  async setres() {
    var data: any = await this.examservice.setfocuslosscounter(this.scheduleid).catch(err => this.cbtservice.showHttpError(err));
    this.losscount = data.flcount;
    clearTimeout(this.countdown);
    if (this.losscount >= this.examination.nooffocusloss) {
      var data2: any = await this.examservice.performfocuslossaction(this.scheduleid).catch(err => this.cbtservice.showHttpError(err));

      if (data2.msg == "ReduceTIme" && this.examination.focuspenalty == "ReduceTIme") {
        this.toastr.warning(`${this.language.youHaveLost} ${this.examination.reducetimelossduration} ${this.language.leavingExamPage}`);
      }
      if (/*data2.msg &&*/ data2.msg != "ReduceTIme") {
        // window.location.href = `${this.thisurl}exam/submit?scheduleid=${this.scheduleid}`;
        this.router.navigate(['/exam/submit'], { queryParams: { scheduleid: this.scheduleid } });
      }
    }
    else {
      this.focuslosswarningmessage = this.language.penalizedWarning;
      this.lostfocus = true
      this.toastr.warning(this.focuslosswarningmessage);

    }
  }

}

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ExamDetails } from '../models/Exam';
import { CbtserviceService } from '../services/cbtservice.service';
import { ExamService } from '../services/exam.service';
import { QuestionserviceService } from '../services/questionservice.service';

import { ProctoringService } from '../services/proctoring.service';
import { UserauthenticationserviceService } from '../services/userauthenticationservice.service';
import { environment } from 'src/environments/environment';
import { Util } from '../interceptors/util';
import { QuestionpagefooterComponent } from '../questionpagefooter/questionpagefooter.component';
import { CurrentUser } from '../models/CurrentUser';
import { OnlineService } from '../services/online.service';
import { LanguageService } from '../services/language.service';
@Component({
  selector: 'app-questionlayout',
  templateUrl: './questionlayout.component.html',
  styleUrls: ['./questionlayout.component.css']
})
export class QuestionlayoutComponent implements OnInit, OnDestroy {
  readonly Essay = "Essay";
  options: any[];
  countdown: any;
  scheduleid: number;
  questionno: number;
  previousscheduleid: any;
  onblurfunction;
  onfocusfunction;
  essaystatusmessage: any;
  @ViewChild('quesfooter') private questionFooter: QuestionpagefooterComponent;
  adminpaused: boolean = false;
  proctorpaused: boolean = false;
  pausereason: string = ''
  timeleft: any;
  status: any;
  markingerrormessage: string;
  dummyQuestionsForFooter: any[];
  timeSpentInterval: any;
  constructor(public questionservice: QuestionserviceService, public cbtservice: CbtserviceService, public router: Router, public activatedroute: ActivatedRoute, public examservice: ExamService, public toastr: ToastrService, private proctorservice: ProctoringService, private userauth: UserauthenticationserviceService, public util: Util,public onlineService:OnlineService,private languageService: LanguageService) { 
    this.onlineService.userOffline.subscribe(offline => {
      if(offline){        
        this.router.navigate(["/logout"], {queryParams: {stayinseb: 1}});
      }
    });

  }
  get language(){
    return this.languageService.language;
  }
  question: any = {};
  examination: ExamDetails = new ExamDetails();
  questioncount: number;
  currentoption
  isloadingdata: boolean = false
  losscount: number = 0;
  lossactionmsg: string = "";
  user: CurrentUser = new CurrentUser();
  thisurl = location.origin + '/';
  focuslosswarningmessage: string = ""
  lostfocus: boolean = false
  retrievecountdown: any;

  answersubmissioncheck: any;
  markingnotworking: boolean;
  //answersubmissionservercheck: any; 
  unpauseinterval: any;
  revalidateuserinterval: any;
  async ngOnInit() {
    this.isloadingdata = true;
    //calling loadPageData here to make sure we load the examination details
    //this.questionservice.getexam
    this.scheduleid = this.activatedroute.snapshot.queryParams.scheduleid;

    this.examination = await this.examservice.getexamwithscheduleid(this.scheduleid);

    await this.loadPageData(this.activatedroute.snapshot.queryParams);

    this.dummyQuestionsForFooter = [];
    const allQuestions = await this.questionservice.getAllQuestionDataFromStorage(this.scheduleid, this.examination.liteMode, this.examination.useCaptcha);

    //debugger;

    for(let i = 0; i < this.question.questionCount; i++){
      this.dummyQuestionsForFooter.push({questionno: i + 1, answered: allQuestions.questions[i].examQuestionSchedule.answered, flagged: allQuestions.questions[i].examQuestionSchedule.flagged});
    }
    
    //this.questionservice.startAnswerSubmission();
    this.user = await this.userauth.getCurrentUser();
    if (!this.user) {
      this.toastr.error(this.language.userSessionNotFound);
      this.router.navigate(['/access/login']);
      return;
    }
    //this.proctorservice.stopProctor();//just in case
    this.urlSubsription();

    //debugger;

    if (this.examination.useproctor) {
      this.proctorservice.setProctorPauseStatus(this.user.username, this.proctorpaused, this.adminpaused, this.pausereason);
    }
    if (!this.examination.liteMode) {
      let iscountingdown=false;
      this.answersubmissioncheck = setInterval(() => {
        this.markingnotworking = this.questionservice.markingnotworking
        this.isloadingdata = this.markingnotworking
        this.markingerrormessage = this.language.networkConnectionLost+"..."
        if(this.markingnotworking && !iscountingdown){
          iscountingdown=true
          this.onlineService.startCountdown();
        }
        else if (!this.markingnotworking){
          iscountingdown=false
          this.onlineService.reset();
        }
      }, 500);

      //we don't need to keep checking isalive because on the server side, we are ensuring that the user is uploading video when the answer is submitted
      // this.revalidateuserinterval = setInterval(async () => {
      //   //doing this interval to ensure that an invalid candidate can't stay logged in and streaming
      //   const data = await this.userauth.getCurrentUser(true);
      //   if(!data){
      //     this.toastr.error('Invalid session');
      //     this.router.navigate(["/logout"]);
      //   }
      // }, 10 * 1000);

      let notmarkedonserver = this.questionservice.getAllQuestionsAnswer().filter(q => !q.saved).map(x => x.schedulequestionid);
      if (notmarkedonserver.length == 0) {
        this.questionservice.markingnotworking = false;
      }
      
      notmarkedonserver.forEach(async (x, index) => {
        let data = this.questionservice.getAnsweredquestion(x)
        this.questionservice.markanswer(data);
        if(index < notmarkedonserver.length - 1){
          await this.util.sleep(1000);//we don't want to request too many captchas via mark answer with too high a frequency
        }
      });

    }
  }

async quitseb(){
  if(this.examination.useSEB){
    await this.proctorservice.quitSEB(this.user?.username).finally(()=>{
      window.location.href = environment.quitseburl;
    });
    
  }
}

  ngOnDestroy(): void {
    //this.questionservice.endAnswerSubmission();
    if (this.onblurfunction) {
      window.removeEventListener('focus', this.onfocusfunction);
      window.removeEventListener('blur', this.onblurfunction);
    }
    clearInterval(this.retrievecountdown);
    // if (this.answersubmissionservercheck) {
    //   clearInterval(this.answersubmissionservercheck);
    // }
    if (this.answersubmissioncheck) {
      clearInterval(this.answersubmissioncheck);
    }
    this.util.usecalculator = false;
    clearInterval(this.unpauseinterval);
    this.retrievecountdown = null;
    this.unpauseinterval = null;
    clearInterval(this.revalidateuserinterval);
    this.revalidateuserinterval = null;
    clearInterval(this.timeSpentInterval)
  }

  async ngAfterViewInit() {
    console.log("retrieve status")
    this.retrieveExamStatus();
    if (!this.unpauseinterval /*&& this.examination.useproctor*/) { this.unpauseinterval = this.pinterval(); }
    this.timeSpentInterval = this.pingTimeSpentInterval();
  }
  async retrieveStatus() {
    if (this.examination.requireadminstart && !this.examination.examstartedbyadmin) {
      var data = await this.questionservice.getquestionandoptions(1, this.examination.liteMode, this.examination.useCaptcha, this.scheduleid).catch(err => { this.cbtservice.showHttpError(err); });
      this.dataretrievedmanipulation(data);

      if (this.retrievecountdown && this.examination.examstartedbyadmin) {
        clearInterval(this.retrievecountdown);
      }
    }
    // console.log(`exam status ${this.examination.examstartedbyadmin}`);
  }

  retrieveExamStatus() {
    this.retrievecountdown = this.ctdinterval();
  }

  ctdinterval() {
    return setInterval(() => {
      if (!this.examination.examstartedbyadmin) {
        this.retrieveStatus();
      }

    }, 1000 * 2);
  }
  async retrievePauseStatus() {
    if (this.scheduleid) {
      let liteMode = this.examination.liteMode;
      var data = await this.examservice.getexampausestatusandtimeleftschedule(this.scheduleid, liteMode).catch(err => { this.cbtservice.showHttpError(err); return; });
      if (data) {
        this.adminpaused = data.adminpaused;
        this.proctorpaused = data.proctorpaused;
        this.pausereason = data.reason;
        if (!liteMode) {
          this.timeleft = data.tleft;
         await this.questionservice.updateQuestionTimeleft(this.scheduleid,liteMode,this.examination.useCaptcha,this.timeleft);
        }
        this.status = data.status;
      }
      if (!this.adminpaused && !this.proctorpaused) {
        //clearInterval(this.unpauseinterval);
        if (!this.question.examQuestion.questionhtml) {//means the question was retreived without html most likely because the exam was paused when the question was being retrieved
          //await this.retrieveStatus(this.questionno);
          await this.loadPageData(this.activatedroute.snapshot.queryParams);
        }
      }
    }
  }

  pinterval() {
    return setInterval(() => {
      this.retrievePauseStatus();
      if (this.examination.useproctor) {
        this.proctorservice.setProctorPauseStatus(this.user.username, this.proctorpaused, this.adminpaused, this.pausereason);
      }
    }, 1000 * 10);
  }

  onFocus() {
    clearTimeout(this.countdown);
    //console.log('OnPageVisibilityChange => visible');
    if (this.lostfocus && this.focuslosswarningmessage != '') {
      this.toastr.warning(this.focuslosswarningmessage);
    }
  };
  onBlur() {
    if (this.examination.checkfocusloss && !this.proctorservice.chatopened) {
      let audio = new Audio();
      audio.src = "/assets/beep.mp3";
      audio.loop = false;
      audio.load();
      audio.play();
      // console.log("audio played");
      const mythis = this;
      this.countdown = setTimeout(() => {
        if (!mythis.proctorservice.chatopened) {
          this.setres();
        }
        // if ((this.examination.nooffocusloss - this.losscount) === 1) {
        //   audio.play();
        //   console.log("audio played")
        // }
      }, 1000 * this.examination.durationofloss);
    }
  }
  urlSubsription() {
    this.activatedroute.queryParams.subscribe(async routeParams => {
      await this.loadPageData(routeParams);
    });


    // console.log(JSON.stringify(this.examination))
    this.cbtservice.getPreviousUrl();
    this.losscount = this.question.focuslosscount;
  }

  private async loadPageData(routeParams) {
    //var tobesaved = this.questionservice.previousquestiondata;
    // console.log("to be saved " + JSON.stringify(tobesaved))
    this.isloadingdata = true;
    this.scheduleid = routeParams.scheduleid;

    this.questionno = routeParams.questionno;
    var data: any = [];

    try{
      if (!this.questionservice.nextquestiondata) {
        // this.allQuestionData = await this.questionservice.getAllQuestionDataFromStorage(this.scheduleid, this.examination.liteMode, this.examination.useCaptcha);
        data = await this.questionservice.getquestionandoptions(this.questionno, this.examination.liteMode, this.examination.useCaptcha, this.scheduleid);
      } else {
        data = this.questionservice.nextquestiondata;
        // this.isloadingdata=false
      }

      this.dataretrievedmanipulation(data);
    }
    catch(error){
      //debugger;
      console.error(error);
      this.cbtservice.showHttpError(error); 
      this.isloadingdata = false;
      throw error;
    }
      // console.log(JSON.stringify(data) + "is data")
    
    
  }

  async dataretrievedmanipulation(data) {
    //debugger;
    if (data.isnotlatestlogin) {
      this.toastr.error(this.language.multipleLoginsDetected);
      this.router.navigate(['/logout']);
      // window.location.href = `${this.thisurl}logout`;

    }
    if (data.logout) {
      this.toastr.error(this.language.unauthorizedAccess);

      this.router.navigate(['/logout'], { queryParams: { unauthorized: true } });
      // window.location.href = `${this.thisurl}logout?unauthorized=true`;

    }
    if (data.examstatus && data.examstatus != '') {
      //debugger;
      this.router.navigate(["/exam/submit"], { queryParams: { scheduleid: this.scheduleid } })
      // window.location.href = `${this.thisurl}exam/submit?scheduleid=${this.scheduleid}`;

    }
    this.examination = data.examination;
    //this.exam = await this.examservice.getexamwithscheduleid(this.scheduleid).toPromise().catch(err => this.toastr.error(err));
    if (this.examination.useproctor) {
      if (this.previousscheduleid && this.previousscheduleid != this.scheduleid) {
        await this.proctorservice.stopProctor('Schedule Id Mismatch');//whenever we move from one exam to another, we have to stop the proctor to ensure another one starts
        this.router.navigate(["/exam/userexams"]);
        return;
      }
      // //TODO: ensure the following happens in a timely manner...e.g 10 seconds...use Promise.race()
      // await this.proctorservice.startProctor(this.user.username, this.user.fullname, this.exam.examid, this.exam.examname);
    }
    this.adminpaused = data.exampaused;
    this.timeleft = data.timeleft;

    //debugger;
    //this.proctorpaused = data.proctorpaused;
    //if (this.proctorpaused || this.adminpaused) {
    if (!this.unpauseinterval /*&& this.examination.useproctor*/) { this.unpauseinterval = this.pinterval(); }
    //}
    if (!data.essayexamstatusmessage) {

      this.isloadingdata = false

      if (this.examination.checkfocusloss && !this.onblurfunction/* && admintestlogin != "True"*/) {
        this.onblurfunction = this.onBlur.bind(this);//have to do it this way because if i just set the event listener to this.setTileSize, the "this" becomes window
        this.onfocusfunction = this.onFocus.bind(this);
        window.addEventListener('focus', this.onfocusfunction);
        window.addEventListener('blur', this.onblurfunction);
      }
      this.question = data;

    }
    this.essaystatusmessage = data.essayexamstatusmessage;
    this.util.usecalculator = data.examination.usecalculator;
    this.util.calculatormode = data.examination.calculatormode;
  }

  getoptions(scheduleid = this.question.examQuestionSchedule.schedulequestionid) {
    this.options = [];
    var ele = document.getElementsByClassName(scheduleid);
    for (var i = 0; i < ele.length; i++) {
      this.getchecked(ele[i]);
    }
  }
  getchecked(element) {
    if (element.checked) {
      this.options.push(element.value.toString())
    }
  }
  // enableradios() {
  //   var buttons = document.getElementsByName(this.question.examQuestionSchedule.schedulequestionid);
  //   buttons.forEach(this.radioenable)
  //   var butons = document.getElementsByClassName(this.question.examQuestionSchedule.schedulequestionid);
  //   for (var i = 0; i < butons.length; i++) {
  //     this.radioenable(butons[i]);
  //   }
  // }
  // radioenable(button) {
  //   button.disabled = false;
  // }
  async qnoclicked(qno: number) {
    let answereditor = document.getElementsByClassName('ql-editor');
    var essayanswer: any = ''
    if (answereditor && answereditor.length > 0) {
      essayanswer = answereditor[0].innerHTML;
    }
    this.isloadingdata = true
    this.getoptions();
    this.questionservice.previousquestiondata = null;
    this.questionservice.nextquestiondata = null;

    var data = {
      "schedulequestionid": this.question.examQuestionSchedule.schedulequestionid,
      "questionid": this.question.examQuestionSchedule.questionid,
      "scheduleid": this.question.examQuestionSchedule.scheduleid,
      "options": this.options,
      "essayanswer": essayanswer,
      "essay": this.Essay
    }
    // console.log(JSON.stringify(data));
    if (this.options && this.options.length > 0) {

      // var saved: any = await this.questionservice.markanswer(data,this.questionservice.hasQuestionDataInStorage()).toPromise().catch(err => this.toastr.error(err)).finally(() => { this.enableradios(); this.isloadingdata = false });
      // if (saved.saved == true) this.router.navigate(["/exam/question"], { queryParams: { scheduleid: data.scheduleid, questionno: qno } });
      // if (saved.logout == true) {
      //   this.toastr.error(saved.msg);
      //   this.router.navigate(["/logout"]);
      // }
      this.questionservice.previousquestiondata = data;
    }
    // else{
    var s = data.scheduleid.toString();
    var n = qno;
    var res = await this.questionservice.getquestionandoptions(n, this.question.examination.liteMode, this.examination.useCaptcha, s).catch(err => { this.cbtservice.showHttpError(err); }).finally(() => this.isloadingdata = false);
    if (res) {
      this.questionservice.nextquestiondata = res;
      this.router.navigate(["/exam/question"], { queryParams: { scheduleid: this.question.examQuestionSchedule.scheduleid, questionno: qno } });
    }
  }

  quesanswered(qno: number) {
    //console.log('Hey '+qno);
    this.questionFooter.answerQuestion(qno);
    if (!this.unpauseinterval && this.examination.useproctor) this.unpauseinterval = this.pinterval();
  }
  quesunanswered(qno: number) {
    //console.log('Hey '+qno);
    this.questionFooter.unanswerQuestion(qno);
    if (!this.unpauseinterval && this.examination.useproctor) this.unpauseinterval = this.pinterval();
  }
  quesflagged(data: any) {
    this.questionFooter.flagquestion(data);
  }
  // @OnPageVisible()
  // logWhenPageVisible(): void {
  //   console.log('OnPageVisibilityChange => visible');
  //   if (this.lostfocus && this.focuslosswarningmessage != '') {
  //     this.toastr.warning(this.focuslosswarningmessage);
  //     this.focuslosswarningmessage = "";
  //     this.lostfocus = false;
  //   }  }

  // @OnPageHidden()
  // logWhenPageHidden(): void {
  //   console.log('OnPageHidden => hidden');
  //   if(this.examination){
  //     this.checkloss();}
  // }

  // @OnPageVisibilityChange()
  // logWhenPageVisibilityChange(visibilityState: AngularPageVisibilityStateEnum): void {
  //   if (AngularPageVisibilityStateEnum[visibilityState]
  //     === AngularPageVisibilityStateEnum[AngularPageVisibilityStateEnum.VISIBLE]) {
  //     clearTimeout(this.countdown);
  //     console.log('OnPageVisibilityChange => visible');
  //     if (this.lostfocus && this.focuslosswarningmessage != '') {
  //       this.toastr.warning(this.focuslosswarningmessage);
  //       // this.focuslosswarningmessage = "";
  //       // this.lostfocus = false;
  //     }
  //   } else if (AngularPageVisibilityStateEnum[visibilityState]
  //     === AngularPageVisibilityStateEnum[AngularPageVisibilityStateEnum.HIDDEN] && this.router.url.includes("question")) {
  //     if (this.examination) {
  //       this.checkloss();
  //     }
  //     console.log('OnPageVisibilityChange => hidden');
  //   }
  // }

  async setres() {
    var data: any = await this.examservice.setfocuslosscounter(this.scheduleid).catch(err => this.cbtservice.showHttpError(err));
    this.losscount = data.flcount;
    clearTimeout(this.countdown);
    if (this.losscount >= this.examination.nooffocusloss) {
      var data2: any = await this.examservice.performfocuslossaction(this.scheduleid).catch(err => this.cbtservice.showHttpError(err));

      if (data2.msg == "ReduceTIme" && this.examination.focuspenalty == "ReduceTIme") {
        this.toastr.warning(`${this.language.youHaveLost} ${this.examination.reducetimelossduration} ${this.language.leavingExamPage}`);
      }
      if (/*data2.msg &&*/ data2.msg != "ReduceTIme") {
        // window.location.href = `${this.thisurl}exam/submit?scheduleid=${this.scheduleid}`;
        this.router.navigate(['/exam/submit'], { queryParams: { scheduleid: this.scheduleid } });
      }
    }
    else {
      this.focuslosswarningmessage = this.language.penalizedWarning
      this.lostfocus = true
      this.toastr.warning(this.focuslosswarningmessage);

    }
  }

  async pingTimeSpentInterval(){
    const pingTimeSec = 30;

    return setInterval(() => {
      this.examservice.pingandupdateTimeSpent(this.scheduleid);
    }, 1000 * pingTimeSec);
  }
}

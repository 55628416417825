<style>
  .pageno {
    background-color: cornflowerblue;
    margin-right: 2vh;
    padding: 1vh;
  }
</style>
<!-- <div class="col-md-11 my-2"> -->
<!-- <nav class="nav-menu float-left d-none d-lg-block">
    <ul>
        <li class="float-left" *ngFor="let item of [].constructor(noofquestions); let i=index"><a class="pageno" href="#">{{i}}</a></li>
    </ul>
</nav> -->
<!-- <ngb-pagination [collectionSize]="noofquestions" [(page)]="page" [maxSize]="40" [rotate]="true"></ngb-pagination>

</div> -->
<!-- <div class="p-2">

    <div class="pagination">
        <ul class="numbers">
            <li [ngClass]="{'active' : qno == this.QuestionNo}" *ngFor="let no of numbers"><a  routerLink="/exam/question" [queryParams]="{scheduleid:examid,questionno:no}"></a>{{no}}</li>
        </ul>
        <br class="clear" />
    </div>
</div> -->
<div class="pager-wrapper">
  <div class="card-footer" style="cursor: pointer;">
    <!-- <nav aria-label="..."> -->
    <ul class="pagination justify-content-center mb-0">
      <!-- <li class="page-item"  *ngIf="qno>1">
        <a class="page-link" (click)="noclicked(qno-1)" tabindex="-1">
          <i class="fas fa-angle-left"></i>
          <span class="sr-only">Previous</span>
        </a>
      </li>
      <li class="page-item" *ngIf="qno!=noofquestions">
        <a class="page-link" (click)="noclicked(qno+1)">
          <i class="fas fa-angle-right"></i>
          <span class="sr-only">Next</span>
        </a>
      </li> -->
      <li *ngFor="let question of questions" [id]="question.questionno" [ngClass]="{'active': qno == question.questionno, 'page-item':true}">
        <a class="page-link h4" [ngClass]="{'badge-answered': question.answered && !question.flagged,'badge-unanswered': !question.answered && !question.flagged ,'badge-flagged':question.flagged }"
           (click)="noclicked(question.questionno)">{{question.questionno}}</a>
        <!-- <a class="page-link"  routerLink="/exam/question" [queryParams]="{scheduleid:scheduleid,questionno:no}">{{no}}</a> -->
      </li>

    </ul>
    <!-- </nav> -->
  </div>
</div>

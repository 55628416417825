import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CurrentUser } from '../models/CurrentUser';
import { CbtserviceService } from './cbtservice.service';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class UserauthenticationserviceService {

  readonly rootURL = environment.CBTAPIURL;
  readonly userTokenLocalStorageKey = environment.userTokenStore;
  readonly sebKeyStore = environment.sebKeyStore;
  readonly tzkey = environment.timezoneapikey;
  //private currentUser: CurrentUser;
  //readonly currentUserToken: string;
  private loginErrorMessage: string;
  private passwordResetSuccessMessage: string;
  private passwordResetErrorMessage: string;
  public isLoading = false;
  public loggedOut = false;
  private isAlivePromise: Promise<any>;

  //constructor
  constructor(private http: HttpClient, private router: Router, private cbtservice: CbtserviceService, private languageService:LanguageService) {
    console.log('creating user authentication service');
    // this.currentUser = {
    //   username: "",
    //   id: 0,
    //   fullname: "",
    //   company: '',
    //   imageversion: 0,
    //   imagetype: ''
    // }
    //this.currentUser.username = "";
  }

  getCurrentUser(revalidate: boolean = false): Promise<CurrentUser> {
    //the currentUser variable will be loaded for the authguard
    //so we will just wait until that happens
    if (revalidate) {
      this.isAlivePromise = null;
    }
    if (!this.isAlivePromise) {
      console.log('creating isalive promise')
      this.isAlivePromise = new Promise((resolve, reject) => {
        this.isAlive().toPromise().then((data: CurrentUser) => {
          if (!data) {
            //if we don't find a user, we want to remove the promise so the server can be checked again next time
            this.isAlivePromise = null;
          }
          resolve(data);
        }).catch((e: any) => {
          //if there is an error getting the user, we want to remove the promise so it can be checked again next time
          console.error(e);
          this.cbtservice.showHttpError(e);
          this.isAlivePromise = null;
          reject(e);
        });
      });
    }

    return this.isAlivePromise;
  }
  /*
   manage the authentication Resposne messages
  */
  setPasswordResetSuccessMessage(msg: string) {
    this.passwordResetSuccessMessage = msg;
  }
  getPasswordResetSuccessMessage() {
    return this.passwordResetSuccessMessage;
  }
  setloginErrorMessage(msg: string) {
    this.loginErrorMessage = msg;
  }
  getloginErrorMessage() {
    return this.loginErrorMessage;
  }
  /*
   End section :manage the authentication Resposne messages
  */

  getCurrentUserToken() {
    // return localStorage.getItem(this.userTokenLocalStorageKey);
    const userTokenStore = this.userTokenLocalStorageKey + "=";
    let cookieData = document.cookie.split(';');
    for (var i = 0; i < cookieData.length; i++) {
      let trimmedCookieData = cookieData[i].trimStart();
      if (trimmedCookieData.indexOf(userTokenStore) == 0) return trimmedCookieData.substring(userTokenStore.length, trimmedCookieData.length);
    }
  }

  getSEBKey() {
    // return localStorage.getItem(this.userTokenLocalStorageKey);
    const sebStore = this.sebKeyStore + "=";
    let cookieData = document.cookie.split(';');
    if (cookieData) {
      for (var i = 0; i < cookieData.length; i++) {
        let trimmedCookieData = cookieData[i].trimStart();
        if (trimmedCookieData.indexOf(sebStore) == 0) return trimmedCookieData.substring(sebStore.length, trimmedCookieData.length);
      }
    }
    return "";
  }

  getIsExternalAuth() {
    const externalStore = "ExternalAuth=";
    let cookieData = document.cookie.split(';');
    for (var i = 0; i < cookieData.length; i++) {
      let trimmedCookieData = cookieData[i].trimStart();
      if (trimmedCookieData.indexOf(externalStore) == 0) return trimmedCookieData.substring(externalStore.length, trimmedCookieData.length);
    }
  }

  setAuthenticatedUserToken(token: string, external: boolean, seb: string) {
    var now = new Date();
    var time = now.getTime();
    time += 24 * 3600 * 1000;
    //now.setTime(time);
    document.cookie = this.userTokenLocalStorageKey + "=" + token;
    document.cookie = `ExternalAuth=${external}`;
    if (seb) {
      document.cookie = this.sebKeyStore + "=" + seb;
    }
    // localStorage.setItem(this.userTokenLocalStorageKey, token);
  }

  logout(unauthorized = false, isexternalauth = false) {
    const langCode = this.languageService.getLanguageCode();
    this.userLogout();
    localStorage.clear();
    let logouturl = '/access/login?';
    if (unauthorized) {
      logouturl = '/access/login?unauthorized=true&';
    }
    let externalsite: boolean = false;
    const schoolinfo: any = null;
    // if (schoolinfo.logoutURL) {
    //   externalsite = true;
    //   logouturl = schoolinfo.logoutURL;
    // }

    //debugger;
    if (isexternalauth && !externalsite) {
      logouturl += 'fromexternalauth=true';
    }

    this.loggedOut = true;
    console.log('clearing out isalive promise')
    this.isAlivePromise = null;
    this.languageService.setLanguageCodeToCookie(langCode);
    if (externalsite) {
      window.location.href = logouturl;
    }
    else {
      this.router.navigateByUrl(logouturl);
    }
  }

  userAuthentitcation(userName, password) {
    let data = {
      "username": userName,
      "password": password
    };

    var req_headers = new HttpHeaders({ "content-type": "application/json", "accept": "application/json" });
    return this.http.post(this.rootURL + 'users/authenticate', data, { headers: req_headers });
  }

  userAuthentication(userid) {
    var data = userid
    var req_headers = new HttpHeaders({ "content-type": "application/json", "accept": "application/json" });
    return this.http.post(this.rootURL + `users/authenticateuserid?userid=${userid}`, data, { headers: req_headers });
  }

  checksebconfig(seb: any, scheduleid: any = 0) {
    return this.http.get(this.rootURL + `users/validatesebkey?key=${encodeURI(seb)}&scheduleid=${scheduleid}`);
  }

  externalUserAuthentication(externaltoken: string, company: string, externalportalname: string) {
    var req_headers = new HttpHeaders({ "content-type": "application/json", "accept": "application/json" });
    return this.http.post(this.rootURL + `users/authenticateexternal`, { jwttoken: externaltoken, company: company, externalportalname: externalportalname }, { headers: req_headers });
  }

  userLogout() {
    // localStorage.removeItem(this.userTokenLocalStorageKey);
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=; max-age=0";
    }
  }

  private isAlive(): Observable<any> {
    return this.http.get(this.rootURL + 'users/isalive');
  }

  loggedIn() {
    const token = this.getCurrentUserToken();
    return token ? true : false;
  }

  getuserprofileimage(userid: number): Observable<any> {
    //debugger;
    return this.http.get(this.rootURL + `users/getimage?examno=${userid}`);
  }

  getUserID(username: string): Observable<any> {
    return this.http.get(this.rootURL + `user/userid/?username=${username}`);
  }

  getClientTimezoneInfo() {
    return this.http.get(`https://api.ipgeolocation.io/timezone?apiKey=${this.tzkey}`)
  }

  getsubdomaincompanyinfo(subdomain: any) {
    return this.http.get(this.rootURL + `users/getsubdomaincompanyinfo?subdomain=${subdomain}`);
  }
}

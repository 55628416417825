import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { OptionDetails } from '../model';

@Component({
  selector: 'app-questionoptions',
  templateUrl: './questionoptions.component.html',
  styleUrls: ['./questionoptions.component.css']
})
export class QuestionoptionsComponent implements OnInit {
  ngOnInit(): void {
  }
@Input() schedulequestionid:number;
  @Input() options :[]
  @Input() multiple:boolean;
  // [
  //   {
  //     "optionhtml": "<em><b>something is happening</b></em>",
  //     "optionid": 123,
  //     "optionno": 1,
  //   },
  //   {
  //     "optionhtml": "<em><b>something is happening</b></em>",
  //     "optionid": 124,
  //     "optionno": 2,
  //   },
  //   {
  //     "optionhtml": "<em><b>something is happening</b></em>",
  //     "optionid": 125,
  //     "optionno": 3,
  //   },
  //   {
  //     "optionhtml": "<em><b>something is happening</b></em>",
  //     "optionid": 125,
  //     "optionno": 3,
  //   },   
  // ];

  @Output() radioselectedvalue: EventEmitter<OptionDetails> = new EventEmitter();
  @Output() checkselectedvalue: EventEmitter<OptionDetails> = new EventEmitter();
  constructor() { }

  OnRadioSelected(option: OptionDetails) {
    this.radioselectedvalue.emit(option);
  }

  oncheckchanged(option:OptionDetails){
this.checkselectedvalue.emit(option);
  }
}

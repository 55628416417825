<div class="chatcomponentdiv">
    <h2 class="chat-title text-center pb-3 border-bottom text-nowrap overflow-hidden text-truncate">
        <!-- <span class="far fa-comment"></span>  -->
        <div class="position-relative pt-3">Messages<button [hidden]="!this.open" class="fa fa-stack bg-grey position-absolute close-button vcircle-fa-button" (click)="close()"><span class="fa fa-times"></span></button></div>
        
    </h2>
    <div #chatscroll class="chatscroll text-body">
       
        <div class="messagesdiv px-3">
            <div *ngFor="let chatmessage of meetingHandler.chatmessages; index as i" 
                [ngClass]="[chatmessage.sendersocketid == meetingHandler.meParticipant.socketid ? 'sent' : 'received', 
                            i > 0 && this.meetingHandler.chatmessages[i - 1].sendersocketid == chatmessage.sendersocketid ? 'mt-1' : 'mt-4']" 
                class="d-flex">
                <!-- <img src="{{chatmessage.senderphotourl}}" alt="Profile picture of {{chatmessage.sendername}}" class="user-img rounded-circle shadow overflow-hidden flex-shrink-0"> -->
                <div class="d-flex flex-column">
                    <div [hidden]="i > 0 && this.meetingHandler.chatmessages[i - 1].sendersocketid == chatmessage.sendersocketid" class="user-name mb-1">{{chatmessage.sendername}}</div>
                    <div class="chatmessage" [innerHTML]="this.util.linkify(chatmessage.message)"></div>    
                </div>
            </div>
        </div>    
    </div>
    <form (ngSubmit)="sendMessage()" class="chatform d-flex border">
        <input (keydown.enter)="sendMessage(true, $event)" type="text" #chattextarea id="chattextarea" class="chattextarea flex-fill border-0 pl-3" placeholder="Enter Message" aria-label="Enter Message">
        <button class="border-0 bg-white text-body px-3" [attr.disabled]="sending ? 'disabled' : null"><i class="fa fa-paper-plane"></i></button>
    </form>
    <div class="chatcomponentbottomdiv"></div>
</div>
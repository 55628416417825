import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Util } from 'src/app/interceptors/util';
import { CandidateComplaint } from 'src/app/model';
import { CbtserviceService } from 'src/app/services/cbtservice.service';
import { ExamService } from 'src/app/services/exam.service';
import { LanguageService } from 'src/app/services/language.service';
import { UserauthenticationserviceService } from 'src/app/services/userauthenticationservice.service';

@Component({
  selector: 'app-view-complaint',
  templateUrl: './view-complaint.component.html',
  styleUrls: ['./view-complaint.component.css']
})
export class ViewComplaintComponent implements OnInit {
  isLoading: boolean = false;
  complaintID: any;
  candidateComplaint: CandidateComplaint = new CandidateComplaint;
  showReply: boolean = false;
  respose: string;
  @ViewChild('responseContainer') private responseContainer!: ElementRef;

  constructor(public util: Util,
    public userauthservice: UserauthenticationserviceService,
    public toastr: ToastrService, private cbtservice: CbtserviceService,
    public examservice: ExamService, public router: Router, public activatedroute: ActivatedRoute, private languageService: LanguageService) { }

  get language() {
    return this.languageService.language;
  }

  async ngOnInit() {
    this.complaintID = this.activatedroute.snapshot.params.id;
    let examNo = this.activatedroute.snapshot.params.examno;
    this.isLoading = true;
    if (this.complaintID) {
      await this.examservice.getcomplaint(this.complaintID, examNo).then((result: any) => {
        this.candidateComplaint = result;
        this.scrollToBottom();
      }).catch((err) => {
        this.cbtservice.showHttpError(err);
        this.router.navigate(['/404']);
      })
        .finally(() => (this.isLoading = false));

    }
  }


  ngAfterViewChecked() {
    this.scrollToBottom();
  }
  async closeBtnClick() {
    try {
      this.isLoading = true;
      await this.examservice.closecomplaint(this.candidateComplaint).then((result: any) => {
        this.candidateComplaint = result;
        this.showReply = false;
        this.toastr.success(this.language.complaintsClosed)
      }).catch((err) => {
        this.isLoading = false;
        this.cbtservice.showHttpError(err);
      })
        .finally(() => (this.isLoading = false));
    } catch (error) {
      console.error(error);
      if (error?.error?.message) {
        this.toastr.error(error.error.message)
      } else if (error?.message) {
        this.toastr.error(error.message)
      } else {
        this.toastr.error(error)
      }
    } finally {
      this.isLoading = false;
    }
  }

  async submitBtnClick() {
    try {
      this.isLoading = true;
      var data = {
        complaintID: this.candidateComplaint.complaintID,
        examNo: this.candidateComplaint.examNo,
        response: this.respose
      }
      await this.examservice.submitReply(data).then((result: any) => {
        this.candidateComplaint = result;
        this.scrollToBottom();
        this.showReply = false;
        this.respose = "";
        this.toastr.success(this.language.replySent)
      }).catch((err) => {
        this.isLoading = false;
        this.cbtservice.showHttpError(err);

      })
        .finally(() => (this.isLoading = false));

    } catch (error) {
      this.cbtservice.showHttpError(error);

    } finally {
      this.isLoading = false;
    }

  }

  scrollToBottom(): void {
    try {
      this.responseContainer.nativeElement.scrollTop = this.responseContainer.nativeElement.scrollHeight;
    } catch (err) {
      console.error('Scroll failed', err);
    }
  }
}


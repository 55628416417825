/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
import * as i0 from "./quit-seb.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./quit-seb.component";
var styles_QuitSEBComponent = [i0.styles];
var RenderType_QuitSEBComponent = /*@__PURE__*/ i1.ɵcrt({ encapsulation: 0, styles: styles_QuitSEBComponent, data: {} });
export { RenderType_QuitSEBComponent as RenderType_QuitSEBComponent };
export function View_QuitSEBComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_QuitSEBComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-quit-seb", [], null, null, null, View_QuitSEBComponent_0, RenderType_QuitSEBComponent)), i1.ɵdid(1, 114688, null, 0, i2.QuitSEBComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QuitSEBComponentNgFactory = /*@__PURE__*/ i1.ɵccf("app-quit-seb", i2.QuitSEBComponent, View_QuitSEBComponent_Host_0, {}, {}, []);
export { QuitSEBComponentNgFactory as QuitSEBComponentNgFactory };

